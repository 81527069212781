export const businessTypes = [
  "Agriculture",
  "Automobile",
  "Chemicals",
  "Clothing",
  "Construction",
  "Digital Marketing",
  "Education",
  "Electronics",
  "Financial Services",
  "Food Processing",
  "Healthcare",
  "IT & BPM",
  "Manufacturing",
  "Media & Marketing",
  "Metals",
  "Oil & Gas",
  "Paper & Packaging",
  "Pharmaceuticals",
  "Railways",
  "Renewable Energy",
  "Retail & E-commerce",
  "Roads & Highways",
  "Software Services",
  "Telecom",
  "Tourism & Hospitality",
  "Transport & Shipping",
  "Other",
  "Individual (Not a Business)",
];
