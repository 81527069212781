import React, { useState, useLayoutEffect } from "react";
import {
  MdOutlineDomainVerification,
  MdDescription,
  MdEmail,
} from "react-icons/md";
import { FaGlobeAmericas } from "react-icons/fa";
import { IoBusiness } from "react-icons/io5";
import { ImPhone, ImLocation } from "react-icons/im";
import {
  RiCloseCircleFill,
  RiEditLine,
  RiCheckboxCircleFill,
} from "react-icons/ri";
import {
  Card,
  CardHeader,
  CardBody,
  Typography,
  Avatar,
  Progress,
} from "@material-tailwind/react";
import { toast } from "react-toastify";

import { useWhatsappContext } from "../../contexts/WhatsappContextProvider";
import { useUserContext } from "../../contexts/UserContextProvider";
import { websiteLinkValidation } from "../utils/websiteLinkValidation";
import { wabConfig } from "../../config/wabConfig";
import userPic from "../../images/props/user-pic.png";

const WhatsAppBusinessProfile = () => {
  const { wbProfileInfo, updateWbProfileInfo, wbPhoneNumberStatus } =
    useWhatsappContext();
  const { isUserAdminOrSuperadmin } = useUserContext();

  const [editAddress, setEditAddress] = useState(false);
  const [editDescription, setEditDescription] = useState(false);
  const [editVertical, setEditVertical] = useState(false);
  const [editEmail, setEditEmail] = useState(false);
  const [editWebsites, setEditWebsites] = useState(false);

  // const [infoType, setInfoType] = useState("");
  // const [openDialog, setOpenDialog] = useState(false);

  const [address, setAddress] = useState("");
  const [description, setDescription] = useState("");
  const [website1, setWebsite1] = useState("");
  const [website2, setWebsite2] = useState("");
  const [email, setEmail] = useState("");
  const [vertical, setVertical] = useState("");

  const allowedVerticals = [
    "APPAREL",
    "AUTO",
    "BEAUTY",
    "EDU",
    "ENTERTAIN",
    "EVENT_PLAN",
    "FINANCE",
    "GOVT",
    "GROCERY",
    "HEALTH",
    "HOTEL",
    "NONPROFIT",
    "PROF_SERVICES",
    "RESTAURANT",
    "RETAIL",
    "TRAVEL",
    "OTHER",
  ];

  const updateAddressOnSubmit = (event) => {
    event.preventDefault();
    updateWbProfileInfo({ address: address });
    setEditAddress(false);
  };

  const updateDescriptionOnSubmit = (event) => {
    event.preventDefault();
    updateWbProfileInfo({ description: description });
    setEditDescription(false);
  };

  const updateVerticalOnSubmit = (event) => {
    event.preventDefault();
    updateWbProfileInfo({ vertical: vertical });
    setEditVertical(false);
  };

  const updateEmailOnSubmit = (event) => {
    event.preventDefault();
    updateWbProfileInfo({ email: email });
    setEditEmail(false);
  };

  const updateWebsitesOnSubmit = (event) => {
    event.preventDefault();

    let websiteFields = [];
    if (website1 !== "") {
      if (websiteLinkValidation(website1)) websiteFields.push(website1);
      else toast.warn("Please enter a valid Website");
    }
    if (website2 !== "") {
      if (websiteLinkValidation(website2)) websiteFields.push(website2);
      else toast.warn("Please enter a valid Website");
    }

    updateWbProfileInfo({ websites: websiteFields });
    setEditWebsites(false);
  };

  useLayoutEffect(() => {
    if (wbProfileInfo?.address) setAddress(wbProfileInfo?.address);
    if (wbProfileInfo?.description) setDescription(wbProfileInfo?.description);
    if (wbProfileInfo?.websites) {
      if (wbProfileInfo?.websites[0]) setWebsite1(wbProfileInfo?.websites[0]);
      if (wbProfileInfo?.websites[1]) setWebsite2(wbProfileInfo?.websites[1]);
    }
    if (wbProfileInfo?.email) setEmail(wbProfileInfo?.email);
    if (wbProfileInfo?.industry) setVertical(wbProfileInfo?.industry);
  }, [wbProfileInfo]);

  return (
    <Card color="white" shadow={true} className="w-full h-fit p-5 pb-8">
      <CardHeader
        color="transparent"
        floated={false}
        shadow={false}
        className="mx-0 pt-0 pb-8 flex items-center gap-3"
      >
        <Avatar
          size="xl"
          variant="circular"
          src={wbProfileInfo?.profilePic ? wbProfileInfo?.profilePic : userPic}
          alt="WhatsApp Profile Pic"
        />
        <div className="w-full flex justify-between items-center gap-2">
          <div className="">
            {wbPhoneNumberStatus?.verified_name && (
              <Typography variant="h5" color="blue-gray">
                {wbPhoneNumberStatus?.verified_name}
              </Typography>
            )}
            {wbPhoneNumberStatus?.display_phone_number && (
              <h6 className="flex items-center gap-1">
                <ImPhone size={16} />
                {wbPhoneNumberStatus?.display_phone_number}
              </h6>
            )}
            <a
              href={wabConfig.waManager.phoneNumbers}
              target="_blank"
              rel="noopener noreferrer"
              className="my-hover-effect-2 after:bg-wabmGreen after:h-[2px] font-semibold text-sm text-wabmBlue"
            >
              Edit Profile ⇗
            </a>
          </div>
          <MdOutlineDomainVerification
            size={24}
            title={`Verification Status = ${wbPhoneNumberStatus?.code_verification_status}`}
            className={
              wbPhoneNumberStatus?.code_verification_status === "VERIFIED"
                ? "text-wabmGreen"
                : wbPhoneNumberStatus?.code_verification_status === "EXPIRED"
                ? "text-red-600"
                : "text-yellow-400"
            }
          />
        </div>
      </CardHeader>
      <CardBody className="p-0 space-y-5 text-sm">
        <div className="space-y-1">
          <span className="mx-5 flex justify-between text-xs">
            <p>WhatsApp Quality Rating</p>
            <p
              className={
                wbPhoneNumberStatus?.quality_rating === "GREEN"
                  ? "text-wabmGreen"
                  : wbPhoneNumberStatus?.quality_rating === "RED"
                  ? "text-red-500"
                  : "text-yellow-500"
              }
            >
              {wbPhoneNumberStatus?.quality_rating}
            </p>
          </span>
          <Progress
            value={
              wbPhoneNumberStatus?.quality_rating === "GREEN"
                ? "100"
                : wbPhoneNumberStatus?.quality_rating === "RED"
                ? "30"
                : "60"
            }
            color={
              wbPhoneNumberStatus?.quality_rating === "GREEN"
                ? "green"
                : wbPhoneNumberStatus?.quality_rating === "RED"
                ? "red"
                : "yellow"
            }
          />
        </div>

        <div
          title="Business Description"
          className="my-input-style-1 w-full h-auto flex justify-between items-start gap-3"
        >
          <div>
            <MdDescription />
          </div>
          {editDescription ? (
            <form
              onSubmit={updateDescriptionOnSubmit}
              className="flex items-center gap-2 flex-1"
            >
              <textarea
                rows="10"
                placeholder="Enter your business description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className="my-input-style-1 w-full h-fit"
              />
              <button
                type="submit"
                className="text-wabmBlue hover:text-wabmGreen transition duration-300 ease-in-out"
              >
                <RiCheckboxCircleFill size={16} />
              </button>
              {editDescription && (
                <button onClick={() => setEditDescription(!editDescription)}>
                  <RiCloseCircleFill
                    size={16}
                    className="text-red-500 hover:text-red-600 transition duration-300 ease-in-out"
                  />
                </button>
              )}
            </form>
          ) : (
            <p className="whitespace-break-spaces">
              {wbProfileInfo?.description}
            </p>
          )}
          {isUserAdminOrSuperadmin() && !editDescription && (
            <button onClick={() => setEditDescription(!editDescription)}>
              <RiEditLine
                size={16}
                className="hover:text-wabmBlue transition duration-300 ease-in-out"
              />
            </button>
          )}
        </div>
        <div
          title="Business Location"
          className="my-input-style-1 h-auto flex items-center gap-3"
        >
          <div>
            <ImLocation />
          </div>
          {editAddress ? (
            <form
              onSubmit={updateAddressOnSubmit}
              className="flex items-center gap-2 flex-1"
            >
              <input
                type="text"
                value={address}
                placeholder="Enter your business address"
                onChange={(e) => setAddress(e.target.value)}
                className="my-input-style-1 w-full h-8"
              />
              <button
                type="submit"
                className="text-wabmBlue hover:text-wabmGreen transition duration-300 ease-in-out"
              >
                <RiCheckboxCircleFill size={16} />
              </button>
              {editAddress && (
                <button onClick={() => setEditAddress(!editAddress)}>
                  <RiCloseCircleFill
                    size={16}
                    className="text-red-500 hover:text-red-600 transition duration-300 ease-in-out"
                  />
                </button>
              )}
            </form>
          ) : (
            <p className="flex-1">
              {wbProfileInfo?.address && wbProfileInfo?.address !== ""
                ? wbProfileInfo?.address
                : "-"}
            </p>
          )}
          {isUserAdminOrSuperadmin() && !editAddress && (
            <button onClick={() => setEditAddress(!editAddress)}>
              <RiEditLine
                size={16}
                className="hover:text-wabmBlue transition duration-300 ease-in-out"
              />
            </button>
          )}
        </div>
        <div
          title="Business Website(s)"
          className="my-input-style-1 h-auto flex items-start gap-3"
        >
          <div>
            <FaGlobeAmericas />
          </div>
          {editWebsites ? (
            <form
              onSubmit={updateWebsitesOnSubmit}
              className="flex items-center gap-2 flex-1"
            >
              <span className="flex flex-col justify-start gap-2">
                <input
                  type="text"
                  value={website1}
                  placeholder="Enter your business website 1"
                  onChange={(e) => setWebsite1(e.target.value)}
                  className="my-input-style-1 w-full h-8"
                />
                <input
                  type="text"
                  value={website2}
                  placeholder="Enter your business website 2"
                  onChange={(e) => setWebsite2(e.target.value)}
                  className="my-input-style-1 w-full h-8"
                />
              </span>
              <button
                type="submit"
                className="text-wabmBlue hover:text-wabmGreen transition duration-300 ease-in-out"
              >
                <RiCheckboxCircleFill size={16} />
              </button>
              {editWebsites && (
                <button onClick={() => setEditWebsites(!editWebsites)}>
                  <RiCloseCircleFill
                    size={16}
                    className="text-red-500 hover:text-red-600 transition duration-300 ease-in-out"
                  />
                </button>
              )}
            </form>
          ) : (
            <div className="flex-1">
              {wbProfileInfo?.websites?.map((site, index) => (
                <p key={index}>{site}</p>
              ))}
            </div>
          )}
          {isUserAdminOrSuperadmin() && !editWebsites && (
            <button onClick={() => setEditWebsites(!editWebsites)}>
              <RiEditLine
                size={16}
                className="hover:text-wabmBlue transition duration-300 ease-in-out"
              />
            </button>
          )}
        </div>
        <div
          title="Business Email"
          className="my-input-style-1 h-auto flex items-center gap-3"
        >
          <div>
            <MdEmail />
          </div>
          {editEmail ? (
            <form
              onSubmit={updateEmailOnSubmit}
              className="flex items-center gap-2 flex-1"
            >
              <input
                type="text"
                value={email}
                placeholder="Enter your business email"
                onChange={(e) => setEmail(e.target.value)}
                className="my-input-style-1 w-full h-8"
              />
              <button
                type="submit"
                className="text-wabmBlue hover:text-wabmGreen transition duration-300 ease-in-out"
              >
                <RiCheckboxCircleFill size={16} />
              </button>
              {editEmail && (
                <button onClick={() => setEditEmail(!editEmail)}>
                  <RiCloseCircleFill
                    size={16}
                    className="text-red-500 hover:text-red-600 transition duration-300 ease-in-out"
                  />
                </button>
              )}
            </form>
          ) : (
            <p className="flex-1">
              {wbProfileInfo?.email && wbProfileInfo?.email !== ""
                ? wbProfileInfo?.email
                : "-"}
            </p>
          )}
          {isUserAdminOrSuperadmin() && !editEmail && (
            <button onClick={() => setEditEmail(!editEmail)}>
              <RiEditLine
                size={16}
                className="hover:text-wabmBlue transition duration-300 ease-in-out"
              />
            </button>
          )}
        </div>
        <div
          title="Business Industry"
          className="my-input-style-1 h-auto flex justify-between gap-3"
        >
          <IoBusiness />
          {editVertical ? (
            <form
              onSubmit={updateVerticalOnSubmit}
              className="flex items-center gap-2 flex-1"
            >
              <select
                name="verticalName"
                value={vertical}
                onChange={(e) => setVertical(e.target.value)}
                className="my-input-style-1 w-full h-8"
              >
                {allowedVerticals.map((item) => (
                  <option key={item}>{item}</option>
                ))}
              </select>
              <button
                type="submit"
                className="text-wabmBlue hover:text-wabmGreen transition duration-300 ease-in-out"
              >
                <RiCheckboxCircleFill size={16} />
              </button>
              {editVertical && (
                <button onClick={() => setEditVertical(!editVertical)}>
                  <RiCloseCircleFill
                    size={16}
                    className="text-red-500 hover:text-red-600 transition duration-300 ease-in-out"
                  />
                </button>
              )}
            </form>
          ) : wbProfileInfo?.industry ? (
            <p className="flex-1">{wbProfileInfo?.industry}</p>
          ) : (
            <p>-</p>
          )}
          {isUserAdminOrSuperadmin() && !editVertical && (
            <button onClick={() => setEditVertical(!editVertical)}>
              <RiEditLine
                size={16}
                className="hover:text-wabmBlue transition duration-300 ease-in-out"
              />
            </button>
          )}
        </div>
      </CardBody>
    </Card>
  );
};

export default WhatsAppBusinessProfile;
