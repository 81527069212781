import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { BiSolidLabel } from "react-icons/bi";
import { BsPersonSquare, BsPersonFillCheck } from "react-icons/bs";
import { FaEyeSlash } from "react-icons/fa";
import { IoMdSearch } from "react-icons/io";
import { MdFilterAltOff, MdLabel, MdClearAll, MdDelete } from "react-icons/md";
import { RiSendPlane2Fill, RiChatOffFill } from "react-icons/ri";
import {
  Button,
  Input,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
} from "@material-tailwind/react";

import { messageDateCheck } from "../utils/messageDateCheck";
import { useUserContext } from "../../contexts/UserContextProvider";
import { useConversationContext } from "../../contexts/ConversationContextProvider";
import { Loader } from "..";
import { refreshMonitorData } from "../../data/messageNotifierServices";
import { contactLabels } from "../../data/contactLabels";
// import { showNotification } from "../utils/showNotification";

const RecentChats = () => {
  const { currentUserInfo } = useUserContext();
  const {
    convGetChatContacts,
    recentContactsList,
    convGetChatHistory,
    selectedChatContactToShow,
    setSelectedChatContactToShow,
    setChatContact,
    socketConnection,
    setChatHistory,
    chatContact,
    updateMessageReadStatus,
    updateLabelChat,
    clearChat,
    deleteChat,
  } = useConversationContext();

  const formatDateTime = () => {
    let date = new Date();
    return date.toISOString().substring(0, 10);
  };

  const [searchContact, setSearchContact] = useState("");
  const [filterBy, setFilterBy] = useState("");
  const [filterLabel, setFilterLabel] = useState("");
  const [openRightClickMenu, setOpenRightClickMenu] = useState(false);
  const [openFilterPopup, setOpenFilterPopup] = useState(false);
  const [openLabelPopup, setOpenLabelPopup] = useState(false);
  const [currContact, setCurrContact] = useState("");
  const [selectedFromDate, setSelectedFromDate] = useState(formatDateTime);
  const [selectedToDate, setSelectedToDate] = useState(formatDateTime);
  const [selectedLabels, setSelectedLabels] = useState([]);

  let subscribe;

  const convertToLocalTime = (timestamp) => {
    const date = new Date(timestamp * 1000);
    return date.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  const handleCheckboxChange = (labelKey) => {
    setSelectedLabels((prevSelectedLabelsKey) => {
      // Check if the object is in the array
      const exists = prevSelectedLabelsKey.some(
        (item) => item.labelKey === labelKey
        //&& item.labelColor === labelColor
      );

      if (exists) {
        // Remove the object if it exists
        return prevSelectedLabelsKey.filter(
          (item) => item.labelKey !== labelKey
          //|| item.labelColor !== labelColor
        );
      } else {
        // Add the object if it doesn't exist
        return [...prevSelectedLabelsKey, { labelKey }];
      }
    });
  };

  const saveLabelOnChat = async () => {
    let selectedLabelsValue = [];
    selectedLabels.map((label) => selectedLabelsValue.push(label.labelKey));

    await updateLabelChat({
      labelValue: selectedLabelsValue,
      phoneNumber: selectedChatContactToShow,
    });
    setSelectedLabels([]);
    setOpenLabelPopup(false);
    convGetChatContacts("");
  };

  const isLabelSelected = (label) => {
    const isExists = selectedLabels.some(
      (item) => item.labelKey === label?.labelKey
    );
    return isExists;
  };

  const handleClearChat = async () => {
    await clearChat(selectedChatContactToShow);
    convGetChatContacts("");
    setSelectedChatContactToShow("");
    setChatContact("");
    setChatHistory(false);
  };

  const handleDeleteChat = async () => {
    await deleteChat(selectedChatContactToShow);
    convGetChatContacts("");
    setSelectedChatContactToShow("");
    setChatContact("");
    setChatHistory(false);
  };

  const handleContactSearch = (currentContact) => {
    const numberRegex = /\d{3,}/g;
    const letterRegex = /[A-z]{3,}/gi;

    if (searchContact === "" || searchContact.length < 3) return true;
    else if (
      numberRegex.test(searchContact) &&
      currentContact?._id &&
      currentContact?._id.includes(searchContact)
    )
      return true;
    else if (
      letterRegex.test(searchContact) &&
      currentContact?.fullName &&
      currentContact?.fullName
        .toLowerCase()
        .includes(searchContact.toLowerCase())
    )
      return true;
    else return false;
  };

  const handleContactFilter = (currentContact) => {
    if (filterBy === "unread") {
      if (!currentContact?.readStatus) return true;
      return false;
    } else if (filterBy === "label") {
      if (
        currentContact?.labels?.length > 0 &&
        currentContact?.labels?.some((item) => item?.labelKey === filterLabel)
      )
        return true;
      return false;
    } else if (filterBy === "replied") {
      if (currentContact?.messageData?.to) return true;
      return false;
    } else if (filterBy === "notReplied") {
      if (currentContact?.messageData?.from) return true;
      return false;
    } else if (filterBy === "assignedUser") {
      if (
        currentContact?.assignedUser &&
        JSON.parse(currentContact?.assignedUser).indexOf(
          currentUserInfo?.agentId
        ) !== -1
      )
        return true;
      return false;
    }
    return true;
  };

  const toShowUnAssignedChats = (agentId, chatAssignedUsers) => {
    if (
      currentUserInfo?.isAdmin === 2 ||
      currentUserInfo?.showUnassignedChat === 1
    )
      return true;

    const assignedUsers = chatAssignedUsers
      ? JSON.parse(chatAssignedUsers)
      : [];

    if (assignedUsers.includes(agentId)) return true;
    return false;
  };

  const fetchChatOnClick = (contact) => {
    if (contact._id) convGetChatHistory(contact._id);
    else {
      toast.warn("Couldn't fetch Chat of Invalid No.");
    }
    setChatContact(contact);
  };

  const socketMessageListener = (msg) => {
    if (recentContactsList.length > 0) {
      let contactIndex = recentContactsList?.findIndex(
        (x) => x?._id === msg?._id
      );

      if (contactIndex !== -1) {
        recentContactsList[contactIndex].messageData = msg.messageData;
        recentContactsList[contactIndex].profile = msg.profile;
        recentContactsList[contactIndex].readStatus = msg.readStatus;
      } else {
        convGetChatContacts("");
      }
    } else {
      convGetChatContacts("");
    }
  };

  useEffect(() => {
    if (socketConnection?.connected) {
      socketConnection.on(currentUserInfo?.wabPhoneId, socketMessageListener);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatContact]);

  useEffect(() => {
    if (searchContact === "") {
      convGetChatContacts("");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    subscribe = refreshMonitorData?.subscribe(() => {
      convGetChatContacts("");
    });
    return () => {
      subscribe?.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchContact]);

  return (
    <main className="bg-wabmBlue w-full h-[40vh] md:h-screen px-1 pb-2 md:col-span-3 lg:col-span-2 drop-shadow-md overflow-y-auto">
      <div className="px-1 pt-2 flex justify-between items-center gap-5">
        <h1 className="wabm-font-style-2 text-white">Chats</h1>
        <div className="w-fit space-x-1">
          <button
            type="button"
            className={`my-button-style-4 hover:text-wabmGreen`}
          >
            <Menu>
              <MenuHandler>
                <MdLabel size={20} />
              </MenuHandler>
              <MenuList className="max-h-72 space-y-1">
                {contactLabels?.map((lbl) => (
                  <MenuItem
                    key={lbl?.labelKey}
                    onClick={() => {
                      setFilterBy("label");
                      setFilterLabel(lbl?.labelKey);
                    }}
                    className={`hover:bg-blue-300 focus:bg-blue-300`}
                    style={{
                      borderLeft: "7px solid",
                      borderLeftColor: lbl?.labelColor,
                    }}
                  >
                    {lbl?.label}
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          </button>
          <button
            type="button"
            onClick={() => setFilterBy("unread")}
            title="Unread"
            className={`my-button-style-4 hover:text-wabmGreen ${
              filterBy === "unread" && "bg-black"
            }`}
          >
            <FaEyeSlash size={20} />
          </button>
          <button
            type="button"
            onClick={() => setFilterBy("replied")}
            title="Replied to"
            className={`my-button-style-4 hover:text-wabmGreen ${
              filterBy === "replied" && "bg-black"
            }`}
          >
            <RiSendPlane2Fill size={20} />
          </button>
          <button
            type="button"
            onClick={() => setFilterBy("notReplied")}
            title="Not replied to"
            className={`my-button-style-4 hover:text-wabmGreen ${
              filterBy === "notReplied" && "bg-black"
            }`}
          >
            <RiChatOffFill size={20} />
          </button>
          <button
            type="button"
            onClick={() => setFilterBy("assignedUser")}
            title="Assigned to you"
            className={`my-button-style-4 hover:text-wabmGreen ${
              filterBy === "assignedUser" && "bg-black"
            }`}
          >
            <BsPersonFillCheck size={20} />
          </button>
          <button
            type="button"
            onClick={() => {
              setFilterBy("");
              setFilterLabel("");
            }}
            title="Clear Filter"
            className={`my-button-style-4 hover:text-wabmGreen ${
              filterBy === "" && "bg-black"
            }`}
          >
            <MdFilterAltOff size={20} />
          </button>
          {/* <button
            type="button"
            onClick={() => {
              setFilterBy("Filter");
              setOpenFilterPopup(true);
            }}
            title="Filter"
            className={`my-button-style-4 hover:text-wabmGreen ${
              filterBy === "Filter" && "bg-black"
            }`}
          >
            <MdFilterAlt size={20} />
          </button> */}
        </div>
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          convGetChatContacts(searchContact);
        }}
        className="bg-wabmBlue w-full mt-3 px-1 pt-3 flex sticky -top-1 z-10 mb-5"
      >
        <Input
          label="Search phone number or name"
          variant="standard"
          color="white"
          placeholder="Example: Sam"
          value={searchContact}
          onChange={(e) => setSearchContact(e.target.value.replace("+", ""))}
          className="pr-20 placeholder:text-blue-200"
          containerProps={{
            className: "min-w-0 w-[77%]",
          }}
        />
        <button
          type="submit"
          className="my-smooth-transition-1 text-white hover:text-wabmAiBlue"
        >
          <IoMdSearch size={20} />
        </button>
      </form>

      <div>
        {recentContactsList?.length === 0 ? (
          <Loader />
        ) : (
          <div className="overflow-y-auto">
            {recentContactsList[0].Empty ? (
              <div className="grid place-content-center text-white text-center">
                <p>Wow, so empty!</p>
                <p>Start a Campaign or Chat Now.</p>
              </div>
            ) : (
              recentContactsList?.map(
                (contact) =>
                  handleContactSearch(contact) &&
                  handleContactFilter(contact) &&
                  toShowUnAssignedChats(
                    currentUserInfo?.agentId,
                    contact?.assignedUser
                  ) && (
                    <button
                      key={contact?._id}
                      title="Right click to view more options"
                      onContextMenu={(e) => {
                        e.preventDefault();
                        setOpenRightClickMenu(!openRightClickMenu);
                        setCurrContact(contact);
                      }}
                      onClick={() => {
                        if (
                          (contact?.assignedUser &&
                            JSON.parse(contact?.assignedUser).indexOf(
                              currentUserInfo?.agentId
                            ) !== -1) ||
                          currentUserInfo?.canViewChat === 1 ||
                          currentUserInfo?.isAdmin === 1 ||
                          currentUserInfo?.isAdmin === 2
                        ) {
                          setChatHistory(true);
                          setSelectedChatContactToShow(contact?._id);
                          fetchChatOnClick(contact);
                        } else {
                          toast.warning(
                            "You are not authorized to open this chat"
                          );
                        }
                      }}
                      className={`my-smooth-transition-1 bg-wabmAiBlue w-full h-16 mb-[2px] pl-5 relative flex items-center gap-5 text-white rounded-sm border-l-8 ${
                        contact?._id === selectedChatContactToShow
                          ? "bg-opacity-90 border-wabmGreen"
                          : "bg-opacity-60 hover:bg-opacity-80 border-wabmAiBlue border-opacity-0 hover:border-infokeyYellow"
                      }`}
                    >
                      <div className="absolute top-2 right-2 flex items-center gap-2">
                        {contact?.messageData?.agent ===
                          currentUserInfo?.agentName && (
                          <RiSendPlane2Fill
                            title="You replied to this chat at last"
                            size={20}
                            className="text-wabmGreen"
                          />
                        )}
                        {contact?.assignedUser &&
                          JSON.parse(contact?.assignedUser).indexOf(
                            currentUserInfo?.agentId
                          ) !== -1 && (
                            <BsPersonFillCheck
                              title="This chat is assigned to you!"
                              size={20}
                              className="text-infokeyYellow"
                            />
                          )}
                        {contact?.labels &&
                          contact?.labels.map((label) => (
                            <BiSolidLabel
                              key={label}
                              className="w-5 h-5 text-white"
                            />
                          ))}
                      </div>
                      <BsPersonSquare
                        size={32}
                        className={
                          contact?._id === selectedChatContactToShow &&
                          "text-wabmGreen"
                        }
                      />
                      <div className="text-sm">
                        <span className="flex gap-1 flex-wrap">
                          {contact?.fullName !== "" &&
                          contact?.fullName !== undefined ? (
                            <h3>{contact?.fullName.substring(0, 40)}</h3>
                          ) : (
                            "+" + contact?._id
                          )}
                        </span>
                        {contact?.messageData?.type === "text" && (
                          <p className="text-slate-200 text-sm text-left">
                            {typeof contact?.messageData?.text?.body !==
                            "string"
                              ? String(
                                  contact?.messageData?.text?.body
                                ).substring(0, 25)
                              : contact?.messageData?.text?.body.substring(
                                  0,
                                  25
                                )}
                          </p>
                        )}
                        {contact?.messageData?.type === "template" && (
                          <p className="text-slate-200 text-sm text-left uppercase">
                            {contact?.messageData?.template?.name.substring(
                              0,
                              25
                            ) + "..."}
                          </p>
                        )}
                      </div>
                      <div
                        className={`absolute bottom-1 right-1 flex items-center gap-2 ${
                          contact?.readStatus
                            ? "text-gray-300"
                            : "text-infokeyYellow"
                        } text-xs`}
                      >
                        {!contact?.readStatus && (
                          <span
                            title="This message is unread"
                            className="bg-infokeyYellow w-2 h-2 rounded-full"
                          />
                        )}
                        <p>
                          {messageDateCheck(
                            contact?.messageData?.timestamp
                          ).toString() === "Today"
                            ? convertToLocalTime(
                                contact?.messageData?.timestamp
                              )
                            : messageDateCheck(
                                contact?.messageData?.timestamp
                              ).toString()}
                        </p>
                      </div>
                    </button>
                  )
              )
            )}
          </div>
        )}
      </div>

      <Dialog
        className="w-48"
        open={openFilterPopup}
        handler={() => setOpenFilterPopup(!openFilterPopup)}
      >
        <DialogHeader className="w-48">
          <div>Filter Criteria</div>
        </DialogHeader>
        <DialogBody className="w-48">
          <div className="flex flex-col justify-start gap-2">
            <div className="flex flex-row gap-2 justify-start">
              <input checked="checked" name="filterCriteria" type="radio" />
              <div>Show unsaved numbers</div>
            </div>
            <div className="flex flex-col justify-start gap-2">
              <div className="flex flex-row gap-2 justify-start">
                <input name="filterCriteria" type="radio" />
                <div>Show last contacted</div>
              </div>
              <div className="flex flex-row gap-2">
                <div className="flex flex-row gap-2">
                  <div>From Date</div>
                  <input
                    value={selectedFromDate}
                    onChange={(event) =>
                      setSelectedFromDate(event.target.value)
                    }
                    className="my-input-style-1 border border-solid border-gray-600"
                    type="date"
                  />
                </div>
                <div className="flex flex-row gap-2">
                  <div>To Date</div>
                  <input
                    value={selectedToDate}
                    onChange={(event) => setSelectedToDate(event.target.value)}
                    className="my-input-style-1 border border-solid border-gray-600"
                    type="date"
                  />
                </div>
              </div>
              <div className="flex flex-row gap-2">
                <div className="flex flex-row gap-2 justify-start">
                  <input name="filterCriteria" type="radio" />
                  <div>Show last contacted in</div>
                </div>
                <input
                  placeholder="Please enter hours"
                  className="my-input-style-1 border border-solid border-gray-600"
                />
              </div>
              <div className="flex flex-row gap-2 justify-start">
                <input name="filterCriteria" type="radio" />
                <div>Show not replied to</div>
              </div>
              <div className="flex flex-row gap-2 justify-start">
                <input name="filterCriteria" type="radio" />
                <div>Show interested client</div>
              </div>
              <div className="flex flex-row gap-2 justify-start">
                <input name="filterCriteria" type="radio" />
                <div>Show existing client</div>
              </div>
            </div>
          </div>
        </DialogBody>
        <DialogFooter>
          <div className="flex flex-row justify-end">
            <Button
              onClick={() => setOpenFilterPopup(false)}
              variant="filled"
              color="green"
            >
              Apply Filter
            </Button>
          </div>
        </DialogFooter>
      </Dialog>

      <Dialog
        open={openLabelPopup}
        handler={() => setOpenLabelPopup(!openLabelPopup)}
      >
        <DialogHeader>
          <div className="space-y-1">
            <h3 className="text-lg font-bold">Select Label</h3>
            <p>Mark your chat with the following labels</p>
          </div>
        </DialogHeader>
        <DialogBody>
          <div className="flex flex-col gap-2">
            {contactLabels?.map((label) => (
              <div
                key={label.label}
                className="flex flex-row gap-2 items-center"
              >
                <input
                  type="checkbox"
                  checked={isLabelSelected(label)}
                  onChange={
                    () => handleCheckboxChange(label.labelKey)
                    //, label.labelColor)
                  }
                />
                <BiSolidLabel
                  className="w-8 h-8"
                  style={{ color: label.labelColor }}
                />
                <div>{label.label}</div>
              </div>
            ))}
          </div>
        </DialogBody>
        <DialogFooter>
          <Button
            onClick={() => {
              saveLabelOnChat();
            }}
            variant="filled"
            color="green"
          >
            Confirm
          </Button>
        </DialogFooter>
      </Dialog>

      <Dialog
        open={openRightClickMenu}
        handler={() => setOpenRightClickMenu(!openRightClickMenu)}
      >
        <DialogHeader>
          <div className="space-y-1">
            <h3 className="text-lg font-bold">
              Options for
              {currContact?.fullName !== "" &&
              currContact?.fullName !== undefined ? (
                <span className="font-bold text-xl ml-2 text-green-600">
                  {currContact?.fullName.substring(0, 40)}
                </span>
              ) : (
                <span className="font-bold text-xl ml-2 text-green-600">
                  {" +" + currContact?._id}
                </span>
              )}
            </h3>
            <p>Select an action you want to perform on the selected chat</p>
          </div>
        </DialogHeader>
        <DialogBody className="flex gap-5">
          <Button
            type="button"
            color="black"
            onClick={() => {
              updateMessageReadStatus(currContact?._id);
              setOpenRightClickMenu(false);
            }}
          >
            Mark as unread
          </Button>
          <Button
            type="button"
            color="blue"
            onClick={() => {
              setSelectedChatContactToShow(currContact?._id);
              let chatLabels = [];
              currContact?.labels?.map((label) =>
                chatLabels.push({
                  labelKey: label,
                })
              );
              setSelectedLabels(chatLabels);
              setOpenLabelPopup(true);
              setOpenRightClickMenu(false);
            }}
            className="flex gap-1"
          >
            <MdLabel /> Label Chat
          </Button>
          <Button
            type="button"
            color="purple"
            onClick={() => {
              handleClearChat();
              setOpenRightClickMenu(false);
              setOpenLabelPopup(false);
            }}
            className="flex gap-1"
          >
            <MdClearAll /> Clear Chat
          </Button>
          <Button
            type="button"
            color="red"
            onClick={() => {
              handleDeleteChat();
              setOpenRightClickMenu(false);
              setOpenLabelPopup(false);
            }}
            className="flex gap-1"
          >
            <MdDelete /> Delete Chat
          </Button>
        </DialogBody>
      </Dialog>
    </main>
  );
};

export default RecentChats;
