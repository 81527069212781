import React, { useState, useEffect } from "react";
import { Button, Drawer, Input, Switch } from "@material-tailwind/react";
import { AiFillMessage } from "react-icons/ai";
import { IoClose } from "react-icons/io5";
import { HiMiniArrowTopRightOnSquare } from "react-icons/hi2";
import { RiShoppingCartFill } from "react-icons/ri";
import { toast } from "react-toastify";

import { paymentConfig } from "../../config/paymentConfig";
import { useUserContext } from "../../contexts/UserContextProvider";
import { usePaymentsContext } from "../../contexts/PaymentsContextProvider";
import { inputUsernameSanitation } from "../utils/inputSanitation";
import { SeoTags } from "..";
import locationWiseData from "../../data/locationWiseData.json";
import wabmLogo192px from "../../images/logo/WaBM-192px.png";

const AddonSubscriptions = () => {
  const { ipCheck, currentRegionCurr } = useUserContext();
  const {
    allPackagePriceDetails,
    getAllPackagePriceDetails,
    applyCouponCode,
    payViaPhonePe,
  } = usePaymentsContext();

  const [isQuarterly, setIsQuarterly] = useState(false);
  const [openCheckout, setOpenCheckout] = useState(false);
  const [selectedSubPackage, setSelectedSubPackage] = useState({});
  const [couponCode, setCouponCode] = useState("");
  const [isCouponValid, setIsCouponValid] = useState(false);
  const [couponStatusMessage, setCouponStatusMessage] = useState("");
  const [finalPrice, setFinalPrice] = useState("");
  const [gstAmount, setGSTAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);

  let selectedRegion =
    locationWiseData?.find((el) => el.cc === currentRegionCurr) ||
    locationWiseData[0];

  const resetStates = () => {
    setSelectedSubPackage({});
    setCouponCode("");
    setIsCouponValid(false);
    setCouponStatusMessage("");
    setFinalPrice("");
    setGSTAmount(0);
    setTotalAmount(0);
  };

  const applyCouponCodeOnClick = async () => {
    const res = await applyCouponCode(
      selectedSubPackage?.packageId,
      selectedSubPackage?.priceDetails?.[isQuarterly ? 1 : 0]?.SubscriptionName,
      selectedRegion?.cc,
      couponCode
    );

    if (res?.Error) {
      setIsCouponValid(false);
      setCouponStatusMessage(res?.Error);
    } else if (res?.isValid) {
      setIsCouponValid(true);
      setFinalPrice(res?.totalAmount);
      const gstAmt = (
        (parseFloat(res?.totalAmount) *
          parseFloat(paymentConfig.subscriptionGST)) /
        100
      ).toFixed();
      setGSTAmount(gstAmt);

      const totalAmt = parseFloat(res?.totalAmount) + parseFloat(gstAmt);
      setTotalAmount(totalAmt);
      setCouponStatusMessage("Coupon Applied Successfully!");
    } else {
      setIsCouponValid(false);
      setCouponStatusMessage(res?.message);
    }
  };

  const payViaPhonePeOnClick = async () => {
    const res = await payViaPhonePe(
      selectedSubPackage?.packageId,
      selectedSubPackage?.priceDetails?.[isQuarterly ? 1 : 0]?.SubscriptionName,
      selectedRegion?.cc,
      couponCode,
      "subscription",
      gstAmount
    );

    if (res?.Error) {
      toast.error(res.Error);
    } else if (res?.paymentLink) {
      window.open(res.paymentLink, "_blank");
      setOpenCheckout(false);
      resetStates();
    } else {
      toast.error(
        "Failed to process the payment request. Please contact WaBM support team."
      );
    }
  };

  useEffect(() => {
    ipCheck();
    getAllPackagePriceDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const gstAmt = (
      (parseFloat(
        selectedSubPackage?.priceDetails?.[isQuarterly ? 1 : 0]?.[
          selectedRegion?.cc
        ]
      ) *
        parseFloat(paymentConfig.subscriptionGST)) /
      100
    ).toFixed(2);
    setGSTAmount(gstAmt);
    const totalAmt =
      parseFloat(
        selectedSubPackage?.priceDetails?.[isQuarterly ? 1 : 0]?.[
          selectedRegion?.cc
        ]
      ) + parseFloat(gstAmt);
    setTotalAmount(totalAmt);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSubPackage]);

  return (
    <div className="">
      <SeoTags title="Add-ons ‣ WaBM" />

      <Drawer
        placement="right"
        open={openCheckout}
        size="720px"
        className="p-4 space-y-5 z-[9996]"
      >
        <div className="w-full flex flex-col gap-5">
          <button
            onClick={() => {
              setOpenCheckout(false);
              resetStates();
            }}
            className="my-button-style-cancel self-end"
          >
            <IoClose size={18} />
          </button>
          <img src={wabmLogo192px} alt="wabm" className="w-20" />
          <h4 className="text-lg">
            👏 Congrats! You're heading onto a Great Start!
          </h4>
        </div>
        <div className="my-card-style-3 py-5 space-y-5">
          <div>
            <p>Selected Package</p>
            <p className="text-2xl font-Montserrat font-bold">
              {selectedSubPackage?.packageName}
            </p>
          </div>
          <div>
            <p>Offer Cost</p>
            <p className="text-xl font-Montserrat font-bold">
              {selectedRegion?.symbol}
              {
                selectedSubPackage?.priceDetails?.[isQuarterly ? 1 : 0]?.[
                  selectedRegion?.cc
                ]
              }
            </p>
          </div>
          <div>
            <div className="bg-slate-100 w-full mt-3 relative flex rounded-md">
              <Input
                type="text"
                label="Coupon Code"
                color="blue"
                value={couponCode}
                onChange={(e) =>
                  setCouponCode(
                    inputUsernameSanitation(e.target.value)
                      .toUpperCase()
                      .slice(0, paymentConfig.couponCodeMaxLength)
                  )
                }
                className="pr-20"
                containerProps={{
                  className: "min-w-0",
                }}
              />
              <Button
                size="sm"
                color={couponCode === "" ? "gray" : "blue"}
                disabled={couponCode?.length < 5}
                onClick={applyCouponCodeOnClick}
                className="!absolute right-1 top-1 rounded"
              >
                + Apply Coupon
              </Button>
            </div>
            {couponStatusMessage !== "" && (
              <p
                className={`mx-2 mt-1 ${
                  isCouponValid ? "text-wabmGreen" : "text-red-600"
                } text-xs italic`}
              >
                {couponStatusMessage}
              </p>
            )}
          </div>
        </div>
        <div className="ml-2 space-y-1">
          <div>
            <p>Final Price Breakup</p>
            <span className="flex flex-row items-center gap-2">
              <span className="text-gray-800 text-xl font-Montserrat font-bold">
                Cost :{" "}
              </span>
              <p className="text-wabmBlue text-xl font-Montserrat font-bold">
                {finalPrice
                  ? finalPrice
                  : selectedSubPackage?.priceDetails?.[isQuarterly ? 1 : 0]?.[
                      selectedRegion?.cc
                    ]}
                <span className="text-gray-800 text-xl font-Montserrat font-bold">
                  /-
                </span>
              </p>
            </span>
            <span className="flex flex-row items-center gap-2">
              <span className="text-gray-800 text-xl font-Montserrat font-bold">
                GST Amount :{" "}
              </span>
              <p className="text-wabmBlue text-xl font-Montserrat font-bold">
                {gstAmount}
                <span className="text-gray-800 text-xl font-Montserrat font-bold">
                  /-
                </span>
                <span className="text-gray-800 text-xl font-Montserrat font-bold">
                  &nbsp;&nbsp;(@{paymentConfig.subscriptionGST}%)
                </span>
              </p>
            </span>
            <span className="flex flex-row items-center gap-2">
              <span className="text-gray-800 text-xl font-Montserrat font-bold">
                Total Amount :{" "}
              </span>
              <p className="text-wabmBlue text-xl font-Montserrat font-bold">
                {totalAmount}
                <span className="text-gray-800 text-xl font-Montserrat font-bold">
                  /-
                </span>
              </p>
            </span>
          </div>
          <Button
            size="sm"
            onClick={payViaPhonePeOnClick}
            className="bg-wabmGreen inline-flex items-center gap-1 rounded"
            formTarget="_blank"
          >
            Pay Now <HiMiniArrowTopRightOnSquare size={18} />
          </Button>
        </div>
      </Drawer>

      <main className="w-full mx-auto my-10 space-y-10">
        <div className="space-y-3">
          <h2 className="text-center text-3xl text-wabmBlue font-bold">
            Add-on Subscriptions ✨
          </h2>
          <p className="text-center text-lg">
            Increase Your conversation count with Add-on subscriptions.
          </p>
        </div>
        <div className="flex flex-col items-center gap-2">
          {isQuarterly ? (
            <p>
              Hurray!{" "}
              <strong className="text-xl text-wabmGreen">
                Savings are applied
              </strong>{" "}
              on Quarterly Subscription.
            </p>
          ) : (
            <p>
              Turn <strong className="text-xl text-wabmGreen">ON</strong> the
              <strong className="text-xl text-wabmGreen">Savings Mode</strong>
            </p>
          )}
          <Switch
            ripple={true}
            className="bg-slate-300 checked:bg-wabmGreen h-full w-full"
            containerProps={{
              className: "w-12 h-8",
            }}
            circleProps={{
              className: "before:hidden left-0.5 border-none",
            }}
            onClick={() => setIsQuarterly(!isQuarterly)}
          />
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-3">
          {allPackagePriceDetails
            ?.filter((x) => x.is_addon_package === 1)
            .map((subPackage) => (
              <div
                key={subPackage?.packageId}
                className="my-smooth-transition-1 hover:bg-emerald-50 h-full p-6 relative rounded-lg border-2 border-wabmBlue"
              >
                <h2 className="text-sm tracking-widest title-font mb-1 font-bold">
                  {subPackage?.packageName}
                </h2>
                {subPackage?.priceDetails?.length > 0 ? (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setOpenCheckout(true);
                      setSelectedSubPackage(subPackage);
                    }}
                    className="my-hover-effect absolute top-5 right-5 text-wabmBlue"
                  >
                    <RiShoppingCartFill size={30} />
                  </button>
                ) : (
                  <button className="my-hover-effect absolute top-5 right-5 text-wabmBlue">
                    <AiFillMessage size={30} />
                  </button>
                )}

                {isQuarterly ? (
                  <div className="pb-3 mb-3 flex items-center gap-1 border-b border-slate-200">
                    <p className="text-3xl text-wabmBlue">
                      {selectedRegion?.symbol}
                    </p>
                    <p className="text-3xl text-wabmBlue">
                      {subPackage?.priceDetails?.[1]?.[selectedRegion?.cc]
                        ? subPackage?.priceDetails?.[1]?.[selectedRegion?.cc]
                        : "Custom"}
                    </p>
                    <p className="ml-2">
                      {subPackage?.priceDetails[1]?.SubscriptionName}
                    </p>
                  </div>
                ) : (
                  <div className="pb-3 mb-3 flex items-center gap-1 border-b border-slate-200">
                    <p className="text-3xl text-wabmBlue">
                      {selectedRegion?.symbol}
                    </p>
                    <p className="text-3xl text-wabmBlue">
                      {subPackage?.priceDetails?.[1]?.[selectedRegion?.cc]
                        ? subPackage?.priceDetails?.[0]?.[selectedRegion?.cc]
                        : "Custom"}
                    </p>
                    <p className="ml-2">
                      {subPackage?.priceDetails[0]?.SubscriptionName}
                    </p>
                  </div>
                )}
                <p className="whitespace-pre-line">
                  {subPackage?.packageDescription.replaceAll("\\n", "\n")}
                </p>
              </div>
            ))}
        </div>
      </main>
    </div>
  );
};

export default AddonSubscriptions;
