import React, { useState, useEffect } from "react";
import Popup from "reactjs-popup";
import DataTable from "react-data-table-component";
import {
  AiFillCheckCircle,
  AiFillExclamationCircle,
  AiFillStop,
} from "react-icons/ai";
import { IoReload } from "react-icons/io5";
import {
  MdDeleteOutline,
  MdOutlineAddToPhotos,
  MdOutlineRemoveCircle,
} from "react-icons/md";
import { Drawer, Typography } from "@material-tailwind/react";

import {
  WhatsappUi,
  CreateWhatsappTemplate,
  DeleteWhatsappTemplate,
  Loader,
  SeoTags,
} from "..";
import { colorsConfig } from "../../config/colorsConfig";
import { useWhatsappContext } from "../../contexts/WhatsappContextProvider";
import { useUserContext } from "../../contexts/UserContextProvider";
import { wabConfig } from "../../config/wabConfig";

const Templates = () => {
  const {
    isLoading,
    allTemplates,
    getTemplates,
    templateReload,
    // allTemplateAnalytics,
    // templateAnalytics,
  } = useWhatsappContext();
  const { isUserAdminOrSuperadmin } = useUserContext();
  const [filteredTemplates, setFilteredTemplates] = useState(allTemplates);
  const [selectedTemplate, setSelectedTemplate] = useState();
  const [templateAction, setTemplateAction] = useState("display");
  const [openBestPractices, setOpenBestPractices] = useState(false);

  const templatesTableColumns = [
    {
      name: "Template Name",
      selector: (row) => row.name,
      sortable: true,
      grow: 3,
    },
    {
      name: "Language",
      selector: (row) => row.language,
      sortable: true,
      minwidth: "auto",
    },
    {
      name: "Category",
      selector: (row) => row.category,
      sortable: true,
      minwidth: "auto",
    },
    {
      name: "Status",
      selector: (row) =>
        row.status === "APPROVED" ? (
          <AiFillCheckCircle
            title={row.status}
            className="w-8 h-8 text-[#25D366]"
          />
        ) : row.status === "PENDING" ? (
          <AiFillExclamationCircle
            title={row.status}
            className="w-8 h-8 text-amber-400"
          />
        ) : row.status === "REJECTED" ? (
          <MdOutlineRemoveCircle
            title={row.status}
            className="w-8 h-8 text-red-500"
          />
        ) : (
          row.status === "DISABLED" && (
            <AiFillStop title={row.status} className="w-8 h-8 text-slate-500" />
          )
        ),
      minwidth: "auto",
    },
    {
      name: "Action",
      selector: (row) => (
        <Popup
          trigger={
            <button className="my-button-style-cancel bg-slate-300 text-slate-900 h-8">
              <MdDeleteOutline className="w-3 h-3" />
            </button>
          }
          position="top center"
        >
          <DeleteWhatsappTemplate deleteTemplate={row.name} />
        </Popup>
      ),
    },
  ];

  const filterTemplatesOnChange = (event) => {
    const keyword = event.target.value.toLowerCase();

    const filteredResults = allTemplates.filter(
      (row) =>
        row.name.toLowerCase().includes(keyword) ||
        row.language.toLowerCase().includes(keyword) ||
        row.category.toLowerCase().includes(keyword) ||
        row.status.toLowerCase().includes(keyword)
    );
    setFilteredTemplates(filteredResults);
  };

  // const getTemplateAnalyticsOnClick = () => {
  //   const timestamps = getUnixTimestamps(3);

  //   if (filteredTemplates?.length > 0) {
  //     const templateIds = filteredTemplates.map((template) => template.id);

  //     // As on 20240329 only "DAILY" granularity is available
  //     allTemplateAnalytics(
  //       "DAILY",
  //       timestamps.start,
  //       timestamps.end,
  //       templateIds
  //     );
  //   }
  // };

  useEffect(() => {
    setFilteredTemplates(allTemplates);
  }, [allTemplates]);

  useEffect(() => {
    if (templateReload === true) getTemplates();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateReload]);

  return (
    <div className="w-full h-full md:h-screen absolute top-0 left-0 grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4">
      <SeoTags title="Message Templates ‣ WaBM" />

      {/* <button onClick={() => getTemplateAnalyticsOnClick()}>
        Get Template Insights
      </button> */}

      <Drawer
        placement="right"
        open={openBestPractices}
        onClose={() => setOpenBestPractices(false)}
        size="720px"
        className="w-full p-4 space-y-5 z-[9996]"
      >
        <Typography variant="h5" color="blue" className="text-center">
          Message Templates Creation Best Practices
        </Typography>
        <ul className="pl-5 space-y-1 text-justify list-decimal">
          <li>
            Variable parameters shouldn't have missing or have mismatched curly
            braces. The correct format is &#123;&#123;1&#125;&#125;.
          </li>
          <li>
            Variable parameters shouldn't contain special characters such as a
            #, $, or %.
          </li>
          <li>
            Variable parameters should be sequential. For example, if
            &#123;&#123;1&#125;&#125;, &#123;&#123;2&#125;&#125;,
            &#123;&#123;4&#125;&#125;, &#123;&#123;5&#125;&#125; is defined but
            &#123;&#123;3&#125;&#125; does not exist, then your template will
            likely get rejected.
          </li>
          <li>
            The message template shouldn't contain content that violates
            WhatsApp's Commerce Policy: When you offer goods or services for
            sale, WhatsApp considers all the messages and media related to your
            goods or services, including any descriptions, prices, fees, taxes
            and/or any required legal disclosures, to constitute transactions.
          </li>
          <li>
            The message template shouldn't contain content that violates the
            WhatsApps Business Policy: Do not request sensitive identifiers from
            users. For example, do not ask people to share full length
            individual payment card numbers, financial account numbers, National
            Identification numbers, or other sensitive identifiers. This also
            includes not requesting documents from users that might contain
            sensitive identifiers. Requesting partial identifiers (ex: last 4
            digits of their Driving License number) is OK.
          </li>
          <li>
            The content shouldn't contain potentially abusive or threatening
            content, such as threatening a customer with legal action or
            threatening to publicly shame them.
          </li>
          <li>
            The message template shouldn't be a duplicate of an existing
            template. If a template is submitted with the same wording in the
            body and footer of an existing template, the duplicate template will
            be rejected.
          </li>
        </ul>
      </Drawer>

      <div
        className={`w-full h-full p-5 md:col-span-2 lg:col-span-3 space-y-3 md:overflow-hidden ${
          templateAction === "create" && "md:col-span-3 lg:col-span-4"
        }`}
      >
        <div>
          <h1 className="text-2xl mb-2">Manage Message Templates</h1>
          <h3>
            Create, Delete, or view insights of all your Whatsapp business
            message templates.
          </h3>
        </div>
        <div className="flex flex-wrap justify-between items-center gap-3">
          <span className="flex items-center gap-3">
            <button
              onClick={() => setTemplateAction("display")}
              className={`my-button-style-5 ${
                templateAction === "display" && "bg-wabmGreen"
              }`}
            >
              All Templates
            </button>
            {templateAction === "display" && (
              <button
                onClick={() => getTemplates()}
                className="my-button-style-5"
              >
                <IoReload size={24} />
              </button>
            )}
            {isUserAdminOrSuperadmin() && (
              <button
                onClick={() => setTemplateAction("create")}
                className={`my-button-style-5 inline-flex items-center gap-1 ${
                  templateAction === "create" && "bg-wabmGreen"
                }`}
              >
                Create <MdOutlineAddToPhotos size={24} />
              </button>
            )}
          </span>
          <span className="flex items-center gap-3">
            <button
              onClick={() => setOpenBestPractices(true)}
              className="my-button-style-5 bg-violet-600 inline-flex items-center gap-1"
            >
              Templates Best Practices
            </button>
            {isUserAdminOrSuperadmin() && (
              <a
                href={wabConfig.waManager.messageTemplates}
                target="_blank"
                rel="noopener noreferrer"
                className="my-hover-effect-2 after:bg-wabmGreen after:h-[2px] font-semibold text-wabmBlue"
              >
                Manage Templates ⇗
              </a>
            )}
          </span>
        </div>
        {templateAction === "display" && (
          <>
            <span className="flex flex-wrap justify-between items-center gap-3">
              <h1 className="text-2xl">Your Templates</h1>
              <input
                type="text"
                placeholder="Search..."
                onChange={filterTemplatesOnChange}
                className="my-input-style-1 border border-slate-300 rounded-md"
              />
            </span>
            {isLoading ? (
              <div className="h-1/3">
                <Loader />
              </div>
            ) : (
              <div className="my-5 space-y-2 rounded-md">
                <DataTable
                  columns={templatesTableColumns}
                  data={filteredTemplates}
                  onRowClicked={(template) => setSelectedTemplate(template)}
                  customStyles={templatesTableStyles}
                  highlightOnHover
                  striped
                  fixedHeader
                  fixedHeaderScrollHeight="65vh"
                />
              </div>
            )}
          </>
        )}
        {templateAction === "create" && <CreateWhatsappTemplate />}
      </div>
      {templateAction === "display" && (
        <WhatsappUi currTemplate={selectedTemplate} />
      )}
    </div>
  );
};

export default Templates;

const templatesTableStyles = {
  rows: {
    style: {},
  },
  headCells: {
    style: {
      backgroundColor: colorsConfig.mediumGrey,
      color: "#FFFFFF",
      fontSize: "0.9rem",
    },
  },
  cells: {
    style: {
      "&:hover": {
        cursor: "pointer",
      },
    },
  },
};
