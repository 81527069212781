import React, { useState, useEffect } from "react";
import {
  Input,
  Typography,
  ButtonGroup,
  Button,
  Select,
  Option,
  Textarea,
  Tooltip,
} from "@material-tailwind/react";
import {
  BsImageFill,
  BsFillPlayBtnFill,
  BsFillFileEarmarkTextFill,
} from "react-icons/bs";
import { IoReload, IoClose, IoInformationCircleSharp } from "react-icons/io5";
import { toast } from "react-toastify";

import { useWhatsappContext } from "../../contexts/WhatsappContextProvider";
import {
  phoneNumberSanitation,
  inputNameSanitation,
  inputUsernameSanitation,
  templateButtonSanitation,
  templateHeaderTextSanitation,
} from "../utils/inputSanitation";
import { waSupportedLanguages } from "../../data/waSupportedLanguages";
import { uploadLimits } from "../../config/appConfig";
import { CustomLoader } from "..";

const CreateWhatsappTemplate = () => {
  const {
    apiCallCreateTemplate,
    startResumableUpload,
    resumableMediaId,
    setResumableMediaId,
    getTemplates,
  } = useWhatsappContext();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [templName, setTemplName] = useState("");
  const [templLang, setTemplLang] = useState("en");
  const [templCategory, setTemplCategory] = useState("");

  // Header State Variables
  const [header, setHeader] = useState(null);
  const [headerType, setHeaderType] = useState("none");
  const [headerText, setHeaderText] = useState("");
  const [headerTextExample, setHeaderTextExample] = useState("");
  const [headerMediaType, setHeaderMediaType] = useState("IMAGE");
  const [headerVariable, setHeaderVariable] = useState(0);

  let headerRegex = /(\{\{[1]\}\})/g;

  // Body State Variables
  const [bodyContent, setBodyContent] = useState("");
  const [bodyVariables, setBodyVariables] = useState(0);
  const [exampleText, setExampleText] = useState({ body_text: [[]] });
  let bodyRegex = /(\{\{\d+\}\})/g;

  // Footer State Variable
  const [footerContent, setFooterContent] = useState("");

  // Button State Variables
  const [buttons, setButtons] = useState([]);
  const buttonTypes = [
    {
      value: "QUICK_REPLY",
      label: "Quick Reply (Custom)",
      disabled: buttons.length >= 10,
    },
    {
      value: "URL",
      label: "Website URL (2 max)",
      disabled: buttons.filter((button) => button.type === "URL").length >= 2,
    },
    {
      value: "PHONE_NUMBER",
      label: "Call Phone Number (1 max)",
      disabled: buttons.some((button) => button.type === "PHONE_NUMBER"),
    },
    {
      value: "COPY_CODE",
      label: "Copy Coupon Code",
      disabled: buttons.some((button) => button.type === "COPY_CODE"),
    },
  ];

  // Button Content Max Length
  const contentMaxLength = {
    title: 512,
    headerText: 60,
    headerExample: 25,
    bodyText: 1024,
    footerText: 60,
    buttons: {
      phone_number: {
        phone_number: 20,
        text: 25,
      },
      url: {
        url: 2000,
        text: 25,
        example: 2000,
      },
      quick_reply: {
        text: 25,
      },
      copy_code: {
        example: 15,
      },
    },
  };

  // Header methods
  const headerSetter = () => {
    if (headerType === "text") {
      let header = {
        type: "HEADER",
        format: headerType,
        text: headerText,
      };

      if (headerVariable > 0) {
        header.example = { header_text: [headerTextExample] };
      }

      setHeader(header);
      setHeaderMediaType("");
    } else if (headerType === "media") {
      setHeader({
        type: "HEADER",
        format: headerMediaType,
        example: {
          header_handle: [resumableMediaId],
        },
      });
      setHeaderVariable(0);
      setHeaderText("");
      setHeaderTextExample("");
    } else {
      setHeader(null);
      setHeaderVariable(0);
      setHeaderText("");
      setHeaderTextExample("");
      setHeaderMediaType("");
    }
  };

  const addVariableToHeaderText = (event) => {
    event.preventDefault();
    if (headerVariable === 0) {
      setHeaderText(headerText + "{{1}}");
      setHeaderVariable(1);
    }
  };

  const headerVariableCounterMethod = () => {
    if (headerRegex.test(headerText)) {
      setHeaderVariable(1);
    } else {
      setHeaderVariable(0);
    }
  };

  const uploadHeaderMediaOnChange = (event) => {
    event.preventDefault();

    const file = event.target.files[0];

    if (file) {
      const formData = new FormData();
      formData.append("file", event.target.files[0]);

      startResumableUpload(formData);
    }
  };

  // Body methods
  const addVariableToBody = (event) => {
    event.preventDefault();

    setBodyContent(bodyContent + "{{" + (bodyVariables + 1) + "}}");
    setBodyVariables(bodyVariables + 1);
  };

  const bodyVariableCounterMethod = () => {
    let bodyVariableCount = (bodyContent.match(bodyRegex) || []).length;
    if (bodyVariableCount !== bodyVariables)
      setBodyVariables(bodyVariableCount);
  };

  const handleExampleInputOnChange = (e, index) => {
    const newValue = e.target.value;

    // Create a copy of the state to avoid mutating it directly
    const updatedExampleText = { ...exampleText };

    // Update the value at the specified index
    updatedExampleText.body_text[0][index] = newValue;

    // Set the updated state
    setExampleText(updatedExampleText);
  };

  // Button methods
  const handleAddButton = (buttonType) => {
    // Check if the maximum limit of 10 buttons is reached
    if (buttons.length >= 10) {
      toast.info("Maximum limit reached. You can't add more buttons.");
      return;
    }

    // Check the type of button and apply specific constraints
    switch (buttonType) {
      case "PHONE_NUMBER":
        // Check if PHONE_NUMBER button is already added
        if (buttons.some((button) => button.type === "PHONE_NUMBER")) {
          toast.info("You can only add 1 PHONE NUMBER button.");
          return;
        }
        break;
      case "URL":
        // Check if maximum 2 URL buttons are already added
        if (buttons.filter((button) => button.type === "URL").length >= 2) {
          toast.info("You can only add up to 2 URL buttons.");
          return;
        }
        break;
      case "COPY_CODE":
        // Check if COPY_CODE button is already added
        if (buttons.some((button) => button.type === "COPY_CODE")) {
          toast.info("You can only add 1 COPY CODE button.");
          return;
        }
        break;
      case "QUICK_REPLY":
        // Check if maximum limit of 10 buttons is reached
        if (buttons.length >= 10) {
          toast.info("Maximum limit reached. You can't add more buttons.");
          return;
        }
        break;
      default:
        toast.error("Invalid button type.");
        return;
    }

    // Add the new button to the state
    setButtons((prevButtons) => [...prevButtons, { type: buttonType }]);
  };

  const handleRemoveButton = (index) => {
    // Remove the button at the specified index from the state
    setButtons((prevButtons) => prevButtons.filter((_, i) => i !== index));
  };

  const orderButtons = (buttons) => {
    // Separate buttons into quickReplyButtons and nonQuickReplyButtons
    const quickReplyButtons = buttons.filter(
      (button) => button.type === "QUICK_REPLY"
    );
    const nonQuickReplyButtons = buttons.filter(
      (button) => button.type !== "QUICK_REPLY"
    );

    // Validate the counts
    const urlCount = nonQuickReplyButtons.filter(
      (button) => button.type === "URL"
    ).length;
    const phoneNumberCount = nonQuickReplyButtons.filter(
      (button) => button.type === "PHONE_NUMBER"
    ).length;

    if (urlCount > 1 || phoneNumberCount > 1) {
      console.log(
        "Invalid grouping: Maximum 1 URL and 1 Phone Number are allowed."
      );
      return null;
    }

    // Reorder buttons as quick reply followed by non quick reply buttons
    const orderedButtons = [...quickReplyButtons, ...nonQuickReplyButtons];

    return orderedButtons;
  };

  const areButtonsValid = (buttons) => {
    let isValid = true;
    let invalidButtonType = null;

    buttons.forEach((button) => {
      switch (button.type) {
        case "PHONE_NUMBER":
          if (
            !(
              button.text &&
              button.phone_number &&
              typeof button.text === "string" &&
              typeof button.phone_number === "string"
            )
          ) {
            isValid = false;
            invalidButtonType = "PHONE_NUMBER";
          }
          break;
        case "URL":
          if (
            !(
              button.text &&
              button.url &&
              typeof button.text === "string" &&
              typeof button.url === "string"
            )
          ) {
            isValid = false;
            invalidButtonType = "URL";
          }
          break;
        case "QUICK_REPLY":
          if (!(button.text && typeof button.text === "string")) {
            isValid = false;
            invalidButtonType = "QUICK_REPLY";
          }
          break;
        case "COPY_CODE":
          if (!(button.example && typeof button.example === "string")) {
            isValid = false;
            invalidButtonType = "COPY_CODE";
          }
          break;
        default:
          isValid = false; // Invalid button type
          invalidButtonType = button.type;
          break;
      }
    });

    if (!isValid) {
      toast.error(`Invalid ${invalidButtonType} button.`);
    }

    return isValid;
  };

  // Reset Template States Function
  const resetTemplateStates = () => {
    setTemplName("");
    setTemplLang("en");
    setTemplCategory("MARKETING");

    setHeader(null);
    setHeaderType("none");
    setHeaderText("");
    setHeaderTextExample("");
    setHeaderMediaType("IMAGE");
    setHeaderVariable(0);
    setResumableMediaId("");
    setBodyContent("");
    setBodyVariables(0);
    setExampleText({ body_text: [[]] });

    setFooterContent("");

    setButtons([]);
  };

  const setAllContent = async () => {
    if (templCategory === "") {
      return {
        Error: true,
        message: "Please select a valid Template Category",
      };
    }

    const messageBody = bodyContent.trim();

    if (messageBody) {
      let tempContent = [];

      if (header && headerType !== "none") {
        tempContent.push(header);
      }

      if (bodyVariables > 0) {
        tempContent.push({
          type: "BODY",
          text: messageBody,
          example: exampleText,
        });
      } else {
        tempContent.push({
          type: "BODY",
          text: messageBody,
        });
      }

      if (footerContent !== "") {
        tempContent.push({
          type: "FOOTER",
          text: footerContent,
        });
      }

      // Check if all buttons are valid
      if (buttons.length > 0) {
        const buttonsStatus = areButtonsValid(buttons);
        if (buttonsStatus) {
          // Ordering template buttons
          try {
            const orderedButtons = orderButtons(buttons);
            tempContent.push({
              type: "BUTTONS",
              buttons: orderedButtons,
            });
          } catch (error) {
            return {
              Error: true,
              message: "Caught error while setting the buttons",
            };
          }
        }
      }

      return { template: tempContent, Error: false };
    }

    return { Error: true, message: "Please set the template body" };
  };

  const validateMessageTemplate = () => {
    const messageBody = bodyContent.trim();

    function hasSequentialNumbering(text) {
      const regex = /{{(\d+)}}/g;
      let lastNumber = 0;

      for (const match of text.matchAll(regex)) {
        const number = parseInt(match[1], 10);

        // Check if the number is not sequential or doesn't start from 1
        if (number !== lastNumber + 1 || (number === 1 && lastNumber !== 0)) {
          return false;
        }

        lastNumber = number;
      }

      return true;
    }

    if (/\{\{\d+\}\}$/.test(messageBody)) {
      toast.info("Template Body cannot end with a variable!");
      return false;
    } else if (/\n{3,}/g.test(messageBody)) {
      toast.info(
        "Template Body cannot have 3 or more newline characters (i.e. 3 or more times enter) at once in the message body."
      );
      return false;
    } else if (!/{{\d+}}/g.test(messageBody)) {
      toast.info(
        "Message body has mismatched curly braces or missing variable. Please note that the correct format is {{1}}."
      );
      return false;
    } else if (/{{\d+}}\s*{{\d+}}/g.test(messageBody)) {
      toast.info(
        "Message cannot have 2 variables side by side or one after another."
      );
      return false;
    } else if (!hasSequentialNumbering(messageBody)) {
      toast.info(
        "Message body has mismatched variables. Please note that the variables must be in sequence and starting from 1, like {{1}} some content {{2}}."
      );
      return false;
    }

    return true;
  };

  const createTemplateOnSubmit = (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    if (!templName || !templLang || !templCategory || !bodyContent.trim()) {
      toast.info("Please set mandatory template values to continue!");
      setIsSubmitting(false);
      return;
    } else {
      const isTemplateValidated =
        bodyVariables > 0 ? validateMessageTemplate() : true;
      if (!isTemplateValidated) {
        setIsSubmitting(false);
        return;
      }
    }

    setAllContent()
      .then(async (content) => {
        if (content?.Error) {
          toast.info(content?.message);
          setIsSubmitting(false);
        } else {
          console.log(
            `Template Name = ${templName} | Template Category = ${templCategory} | Template Language = ${templLang}`
          );
          const templResp = await apiCallCreateTemplate({
            name: templName,
            category: templCategory,
            language: templLang,
            components: content?.template,
          });

          if (templResp) {
            resetTemplateStates();
            getTemplates();
          }
          setIsSubmitting(false);
        }
      })
      .catch((err) => {
        console.log("Encountered error while setting template data | err", err);
        toast.error("Encountered error while setting template data");
        setIsSubmitting(false);
      });
  };

  useEffect(() => {
    headerVariableCounterMethod();
    headerSetter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headerText, headerType, headerMediaType, resumableMediaId]);

  useEffect(() => {
    bodyVariableCounterMethod();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bodyContent]);

  useEffect(() => {
    setExampleText({
      body_text: [
        Array.from(
          { length: bodyVariables }
          // (_, index) => { }//`Element ${index + 1}`
        ),
      ],
    });
  }, [bodyVariables]);

  return (
    <>
      {isSubmitting && <CustomLoader />}
      <form
        onSubmit={createTemplateOnSubmit}
        className="my-card-style-1 md:h-5/6 py-5 flex flex-col gap-5 overflow-y-auto border-2 border-slate-400"
      >
        <Input
          variant="outlined"
          label="Template name"
          color="blue"
          required
          placeholder="Example: marketing_offer_new_year_2025"
          value={templName}
          onChange={(e) =>
            setTemplName(
              inputUsernameSanitation(e.target.value).slice(
                0,
                contentMaxLength.title
              )
            )
          }
          className="my-card-style-1 bg-white"
        />

        <div className="my-card-style-1 bg-white space-y-1">
          <h3>
            Category<p className="inline-flex text-red-600">*</p>
          </h3>
          <p className="text-sm mb-2">
            Choose a category that best describes your message template.
          </p>
          <div className="w-full grid grid-cols-1 lg:grid-cols-3 gap-2">
            <div className="my-card-style-1 flex items-center gap-5">
              <input
                type="radio"
                id="marketing"
                name="description"
                value="MARKETING"
                checked={templCategory === "MARKETING"}
                onChange={() => setTemplCategory("MARKETING")}
                className=""
              />
              <label htmlFor="marketing">
                <Typography color="blue-gray" className="font-medium">
                  Marketing
                </Typography>
                <Typography
                  variant="small"
                  color="gray"
                  className="font-normal"
                >
                  Promotions or information about your business, products or
                  services. Or any message that isn't utility or authentication.
                </Typography>
              </label>
            </div>
            <div className="my-card-style-1 flex items-center gap-5">
              <input
                type="radio"
                id="utility"
                name="description"
                value="UTILITY"
                checked={templCategory === "UTILITY"}
                onChange={() => setTemplCategory("UTILITY")}
                className=""
              />
              <label htmlFor="utility">
                <Typography color="blue-gray" className="font-medium">
                  Utility
                </Typography>
                <Typography
                  variant="small"
                  color="gray"
                  className="font-normal"
                >
                  Messages about a specific transaction, account, order or
                  customer request.
                </Typography>
              </label>
            </div>
            <div className="my-card-style-1 flex items-center gap-5">
              <input
                type="radio"
                id="authentication"
                name="description"
                value="AUTHENTICATION"
                checked={templCategory === "AUTHENTICATION"}
                onChange={() => setTemplCategory("AUTHENTICATION")}
                className=""
              />
              <label htmlFor="authentication">
                <Typography color="blue-gray" className="font-medium">
                  Authentication
                </Typography>
                <Typography
                  variant="small"
                  color="gray"
                  className="font-normal"
                >
                  One-time passwords that your customers use to authenticate a
                  transaction or login.
                </Typography>
              </label>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
          <div className="my-card-style-1 bg-white space-y-1">
            <h3>
              Language<p className="inline-flex text-red-600">*</p>
            </h3>
            <p className="text-sm pb-2">
              Choose language for your message template.
            </p>
            <Select
              variant="outlined"
              label="Select Language"
              color="blue"
              value={templLang}
              onChange={(e) => setTemplLang(e)}
            >
              {Object.entries(waSupportedLanguages).map(([code, language]) => (
                <Option
                  value={code}
                  key={code}
                  className={`${
                    templLang === code && "bg-wabmGreen"
                  } hover:bg-blue-300 focus:bg-blue-300`}
                >
                  {language}
                </Option>
              ))}
            </Select>
          </div>
          {templName !== "" && templLang !== "" && templCategory !== "" && (
            <div className="my-card-style-1 bg-white">
              <p className="text-sm mb-3">
                Add a title or choose which type of media you'll use for this
                header.
              </p>
              <Select
                variant="outlined"
                label="Select Header (optional)"
                color="blue"
                onChange={(e) => setHeaderType(e)}
              >
                <Option
                  value="none"
                  className="hover:bg-blue-300 focus:bg-blue-300"
                >
                  none
                </Option>
                <Option
                  value="text"
                  className="hover:bg-blue-300 focus:bg-blue-300"
                >
                  text
                </Option>
                <Option
                  value="media"
                  className="hover:bg-blue-300 focus:bg-blue-300"
                >
                  media
                </Option>
              </Select>

              {headerType === "text" && (
                <div className="space-y-2">
                  <div className="w-full mt-3 relative flex">
                    <Input
                      type="text"
                      label={
                        templLang
                          ? `Enter your ${waSupportedLanguages[templLang]} text`
                          : "Enter your text"
                      }
                      color="blue"
                      placeholder="Example: Hi {{1}}"
                      required
                      value={headerText}
                      onChange={(e) =>
                        setHeaderText(
                          templateHeaderTextSanitation(
                            e.target.value.slice(0, contentMaxLength.headerText)
                          )
                        )
                      }
                      className="pr-20"
                      containerProps={{
                        className: "min-w-0",
                      }}
                    />
                    <Button
                      size="sm"
                      color={headerText === "" ? "gray" : "blue"}
                      disabled={
                        headerText === "" ||
                        (headerText !== "" && headerVariable >= 1)
                      }
                      onClick={addVariableToHeaderText}
                      className="!absolute right-1 top-1 rounded"
                    >
                      + Add Variable
                    </Button>
                  </div>
                  {headerVariable > 0 && (
                    <Input
                      variant="outlined"
                      label="Header Example"
                      color="blue"
                      required
                      value={headerTextExample}
                      onChange={(e) =>
                        setHeaderTextExample(
                          e.target.value.slice(
                            0,
                            contentMaxLength.headerExample
                          )
                        )
                      }
                      className="my-card-style-1 bg-white"
                    />
                  )}
                </div>
              )}
              {headerType === "media" && (
                <div>
                  <ButtonGroup ripple={true} className="mt-3">
                    <Button
                      className={` ${
                        headerMediaType === "IMAGE"
                          ? "bg-wabmGreen text-wabmAiBlue"
                          : ""
                      }`}
                      onClick={() => setHeaderMediaType("IMAGE")}
                    >
                      <BsImageFill size={24} className="mx-auto mb-1" />
                      <p className="font-semibold">Image</p>
                    </Button>
                    <Button
                      className={
                        headerMediaType === "VIDEO"
                          ? "bg-wabmGreen text-wabmAiBlue"
                          : ""
                      }
                      onClick={() => setHeaderMediaType("VIDEO")}
                    >
                      <BsFillPlayBtnFill size={24} className="mx-auto mb-1" />
                      <p className="font-semibold">Video</p>
                    </Button>
                    <Button
                      className={
                        headerMediaType === "DOCUMENT"
                          ? "bg-wabmGreen text-wabmAiBlue"
                          : ""
                      }
                      onClick={() => setHeaderMediaType("DOCUMENT")}
                    >
                      <BsFillFileEarmarkTextFill
                        size={24}
                        className="mx-auto mb-1"
                      />
                      <p className="font-semibold">Document</p>
                    </Button>
                  </ButtonGroup>

                  {headerType === "media" && (
                    <div className="py-3">Please provide Media Example</div>
                  )}
                  {headerMediaType === "IMAGE" ? (
                    <input
                      accept="image/jpeg, image/jpg, image/png"
                      max={uploadLimits.image}
                      id="image-button"
                      type="file"
                      required
                      onChange={uploadHeaderMediaOnChange}
                      className="dropzone-box"
                    />
                  ) : headerMediaType === "VIDEO" ? (
                    <input
                      accept="video/mp4"
                      max={uploadLimits.video}
                      id="video-button"
                      type="file"
                      required
                      onChange={uploadHeaderMediaOnChange}
                      className="dropzone-box"
                    />
                  ) : (
                    <input
                      accept="application/pdf"
                      max={uploadLimits.document}
                      id="file-button"
                      type="file"
                      required
                      onChange={uploadHeaderMediaOnChange}
                      className="dropzone-box"
                    />
                  )}
                </div>
              )}
            </div>
          )}
        </div>

        {templName !== "" && templLang !== "" && templCategory !== "" && (
          <div className="my-card-style-1 bg-white relative space-y-2">
            <h3>
              Body<p className="inline-flex text-red-600">*</p>
            </h3>
            <p className="text-sm mb-2">
              Enter the message content in {waSupportedLanguages[templLang]}{" "}
              language.
            </p>
            <Textarea
              label={
                templLang
                  ? `Message Body content (${waSupportedLanguages[templLang]})`
                  : "Enter your text"
              }
              required
              value={bodyContent}
              color="blue"
              rows={10}
              resize={true}
              onChange={(e) =>
                setBodyContent(
                  e.target.value.slice(0, contentMaxLength.bodyText)
                )
              }
            />
            <Button
              size="sm"
              color={bodyContent.trim() === "" ? "gray" : "blue"}
              disabled={bodyContent.trim() === ""}
              onClick={addVariableToBody}
              className="rounded"
            >
              + Add Variable
            </Button>

            {bodyVariables > 0 && (
              <div className="pt-5 space-y-2 text-gray-600">
                <h4>Samples for body content</h4>
                <p className="text-xs">
                  To help WhatsApp review your message template, please add an
                  example for each variable in your body text. Do not use real
                  customer information. WhatsApp (Meta) reviews templates and
                  variable parameters to protect the security and integrity of
                  this service.
                </p>
                <div className="pt-5 space-y-5">
                  {exampleText.body_text &&
                    exampleText.body_text[0] &&
                    exampleText.body_text[0]?.map((value, index) => (
                      <Input
                        variant="outlined"
                        label={`Variable ${index + 1}`}
                        color="blue"
                        required
                        key={index}
                        value={value}
                        onChange={(e) => handleExampleInputOnChange(e, index)}
                        className="my-card-style-1 bg-white"
                      />
                    ))}
                </div>
              </div>
            )}
          </div>
        )}

        {templName !== "" && templLang !== "" && templCategory !== "" && (
          <div className="my-card-style-1 bg-white">
            <p className="text-sm mb-3">
              Add a short line of text to the bottom of your message template.
            </p>
            <Input
              variant="outlined"
              label="Footer (optional)"
              color="blue"
              placeholder="Example: WaBM | An Infokey Brand"
              value={footerContent}
              onChange={(e) =>
                setFooterContent(
                  e.target.value.slice(0, contentMaxLength.footerText)
                )
              }
              className="my-card-style-1 bg-white"
            />
          </div>
        )}

        {templName !== "" && templLang !== "" && templCategory !== "" && (
          <div className="my-card-style-1 bg-white">
            <p className="text-sm mb-3">
              Create buttons that let customers respond to your message or take
              action.
            </p>
            <div className="inline-flex items-center gap-2">
              <ButtonGroup variant="gradient" color="blue">
                {buttonTypes.map((buttonType) => (
                  <Button
                    type="button"
                    key={buttonType.value}
                    disabled={buttonType.disabled}
                    onClick={() => handleAddButton(buttonType.value)}
                    className=""
                  >
                    {buttonType.label}
                  </Button>
                ))}
              </ButtonGroup>

              <Tooltip
                content={
                  <div>
                    <h4 className="font-medium mb-2">
                      Please take care of the following while creating buttons.
                    </h4>
                    <p className="">
                      ▸ A Total 10 buttons are allowed including all of the
                      available options.
                    </p>
                    <p className="">
                      ▸ For more than 3 button options, third button will be
                      replaced by "See all options"
                    </p>
                    <p className="">
                      ▸ A maximum of 1 phone number button can be placed.
                    </p>
                    <p className="">
                      ▸ A maximum of 2 URL buttons can be placed.
                    </p>
                    <p className="">
                      ▸ A maximum of 1 c opy code button can be placed.
                    </p>
                  </div>
                }
                className="bg-wabmBlue bg-opacity-90"
              >
                <span className="w-5">
                  <IoInformationCircleSharp />
                </span>
              </Tooltip>
            </div>

            <div className="grid md:grid-cols-2 gap-x-5 gap-y-2">
              {buttons.map((button, index) => (
                <div key={index} className="mt-5 relative">
                  {button.type === "PHONE_NUMBER" && (
                    <div className="wbm-card-2 p-3 space-y-3">
                      <Input
                        variant="outlined"
                        label="Phone Button Text"
                        color="blue"
                        required
                        placeholder="Example: Call Us"
                        value={button.text || ""}
                        onChange={(e) => {
                          const newText = e.target.value.slice(
                            0,
                            contentMaxLength.buttons.phone_number.text
                          );
                          setButtons((prevButtons) =>
                            prevButtons.map((btn, i) =>
                              i === index
                                ? { ...btn, text: inputNameSanitation(newText) }
                                : btn
                            )
                          );
                        }}
                        className="my-card-style-1 bg-white"
                      />
                      <Input
                        variant="outlined"
                        label="Phone Number without +"
                        color="blue"
                        required
                        placeholder="91XXXXXXX47"
                        value={button.phone_number || ""}
                        onChange={(e) => {
                          const newPhoneNumber = e.target.value.slice(
                            0,
                            contentMaxLength.buttons.phone_number.phone_number
                          );
                          setButtons((prevButtons) =>
                            prevButtons.map((btn, i) =>
                              i === index
                                ? {
                                    ...btn,
                                    phone_number:
                                      phoneNumberSanitation(newPhoneNumber),
                                  }
                                : btn
                            )
                          );
                        }}
                        className="my-card-style-1 bg-white"
                      />
                    </div>
                  )}

                  {button.type === "URL" && (
                    <div className="wbm-card-2 p-3 space-y-3">
                      <Input
                        variant="outlined"
                        label="Button Text"
                        color="blue"
                        required
                        placeholder="Example: Click to visit website"
                        value={button.text || ""}
                        onChange={(e) => {
                          const newText = e.target.value.slice(
                            0,
                            contentMaxLength.buttons.url.text
                          );
                          setButtons((prevButtons) =>
                            prevButtons.map((btn, i) =>
                              i === index
                                ? { ...btn, text: inputNameSanitation(newText) }
                                : btn
                            )
                          );
                        }}
                        className="my-card-style-1 bg-white"
                      />
                      <Input
                        variant="outlined"
                        label="URL (Link)"
                        color="blue"
                        required
                        placeholder="https://www.example.com"
                        value={button.url || ""}
                        onChange={(e) => {
                          const newUrl = e.target.value.slice(
                            0,
                            contentMaxLength.buttons.url.url
                          );
                          setButtons((prevButtons) =>
                            prevButtons.map((btn, i) =>
                              i === index ? { ...btn, url: newUrl } : btn
                            )
                          );
                        }}
                        className="my-card-style-1 bg-white"
                      />
                    </div>
                  )}

                  {button.type === "QUICK_REPLY" && (
                    <div className="wbm-card-2 p-3 space-y-3">
                      <Input
                        variant="outlined"
                        label="Quick Reply Text"
                        color="blue"
                        required
                        placeholder="Example: Tell me more"
                        value={button.text || ""}
                        onChange={(e) => {
                          const newText = e.target.value.slice(
                            0,
                            contentMaxLength.buttons.quick_reply.text
                          );
                          setButtons((prevButtons) =>
                            prevButtons.map((btn, i) =>
                              i === index
                                ? {
                                    ...btn,
                                    text: templateButtonSanitation(newText),
                                  }
                                : btn
                            )
                          );
                        }}
                        className="my-card-style-1 bg-white"
                      />
                    </div>
                  )}

                  {button.type === "COPY_CODE" && (
                    <div className="wbm-card-2 p-3 space-y-3">
                      <Input
                        variant="outlined"
                        label="Copy Code Example"
                        color="blue"
                        required
                        placeholder="Example: HAPPYNEWYEAR"
                        value={button.example || ""}
                        onChange={(e) => {
                          const newText = e.target.value.slice(
                            0,
                            contentMaxLength.buttons.copy_code.example
                          );
                          setButtons((prevButtons) =>
                            prevButtons.map((btn, i) =>
                              i === index
                                ? {
                                    ...btn,
                                    example: inputNameSanitation(newText),
                                  }
                                : btn
                            )
                          );
                        }}
                        className="my-card-style-1 bg-white"
                      />
                    </div>
                  )}

                  <button
                    type="button"
                    onClick={() => handleRemoveButton(index)}
                    className="my-button-style-cancel p-1 absolute top-1 right-1"
                  >
                    <IoClose size={18} />
                  </button>
                </div>
              ))}
            </div>
          </div>
        )}

        <span className="inline-flex items-center gap-2">
          <button
            type="button"
            onClick={resetTemplateStates}
            className="my-button-style-cancel"
          >
            <IoReload size={24} />
          </button>
          <button
            type="submit"
            disabled={
              !templName ||
              !templLang ||
              !templCategory ||
              !bodyContent.trim() ||
              isSubmitting
            }
            className="my-button-style-5 disabled:bg-slate-400"
          >
            Create Template
          </button>
        </span>
      </form>
    </>
  );
};

export default CreateWhatsappTemplate;
