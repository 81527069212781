import React, { useState, createContext, useContext } from "react";
import { toast } from "react-toastify";

import { appSettings } from "../config/appConfig";
import locationWiseData from "../data/locationWiseData.json";

const UserContext = createContext();

export const UserContextProvider = ({ children }) => {
  const baseUrl = appSettings.serverBaseUrl;

  const [currentRegionCurr, setCurrentRegionCurr] = useState(
    locationWiseData[0]?.region
  );
  const [registrationStatus, setRegistrationStatus] = useState(false);
  const [daypart, setDaypart] = useState("");
  const [sentDesktopNotification, setSentDesktopNotification] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [currentUserInfo, setCurrentUserInfo] = useState({});
  const [initiatedPasswordReset, setInitiatedPasswordReset] = useState(false);
  const [userAgentsList, setUserAgentsList] = useState([]);
  const [userConversationLimits, setUserConversationLimits] = useState({});
  const [hideSidebar, setHideSidebar] = useState(false);

  const resetUserContextStates = () => {
    setIsLoading(false);
    setIsLoggedIn(false);
    setCurrentUserInfo([]);
    setInitiatedPasswordReset(false);
    setUserAgentsList([]);
  };

  const daypartFunction = () => {
    let currentHour = new Date().getHours();
    if (currentHour >= 5 && currentHour < 12) {
      setDaypart("Morning");
    } else if (currentHour >= 12 && currentHour < 16) {
      setDaypart("Afternoon");
    } else {
      setDaypart("Evening");
    }
  };

  const ipCheck = async () => {
    try {
      const response = await fetch("https://freeipapi.com/api/json", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const result = await response.json();
      setCurrentRegionCurr(
        result?.currency?.code ? result?.currency?.code : "INR"
      );

      return result;
    } catch (error) {
      console.error("ipCheck error", error);
    }
  };

  const requestNotificationPermission = () => {
    if (Notification.permission !== "granted") {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          toast.success("Desktop notifications enabled");
        } else if (!sentDesktopNotification) {
          setSentDesktopNotification(true);
          toast.warn(
            "Enable Desktop notifications to never miss out on user messages"
          );
        }
      });
    }
  };

  const loginCheck = () => {
    setIsLoading(true);
    fetch(`${baseUrl}/api/login`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response?.isValidToken) {
          setIsLoggedIn(true);
          setCurrentUserInfo((prev) => ({
            ...prev,
            ...response,
          }));
        } else {
          setIsLoggedIn(false);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setIsLoggedIn(false);
      });
  };

  const apiCallLogin = async (corporateId, agentName, password) => {
    setIsLoading(true);
    const userLoginRegion = await ipCheck();

    fetch(`${baseUrl}/api/login`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        corporateId: corporateId,
        agentName: agentName,
        password: password,
        regionDetails: userLoginRegion,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response?.isValidToken === true) {
          setIsLoggedIn(true);

          if (response?.message) {
            toast.success(response.message);
          }

          setCurrentUserInfo((prev) => ({
            ...prev,
            ...response,
          }));
        } else {
          setIsLoggedIn(false);
          if (response.Error) toast.error(response.Error);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        toast.error("Failed to make login request");
        setIsLoading(false);
        setIsLoggedIn(false);
      });
  };

  const apiCallLogout = () => {
    setIsLoading(true);
    fetch(`${baseUrl}/api/logout`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response?.isValidToken === false) {
          resetUserContextStates();
          toast.success("Logged out Successfully");
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoggedIn(false);
        setIsLoading(false);
      });
  };

  const apiCallRegister = async (
    firstName,
    lastName,
    businessName,
    businessType,
    email,
    phoneNo
  ) => {
    setIsLoading(true);
    fetch(`${baseUrl}/api/register`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        firstName,
        lastName,
        businessName,
        businessType,
        email,
        phoneNo,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        setIsLoading(false);
        if (response?.Error) {
          toast.error(response?.Error);
        } else {
          setRegistrationStatus(true);
          toast.success(response?.message);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error.Error);
      });
  };

  const isUserAdminOrSuperadmin = () => {
    if (currentUserInfo?.isAdmin === 1 || currentUserInfo?.isAdmin === 2)
      return true;

    return false;
  };

  const apiCallChangePassword = (currentPassword, newPassword) => {
    fetch(`${baseUrl}/api/change-password`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ currentPassword, newPassword }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.message) {
          toast.info(response.message);
        } else {
          toast.error("Unable to change password right now");
        }
      })
      .catch((error) => {
        toast.error(error.Error);
      });
  };

  const apiCallInitiateResetPassword = (corporateId, agentName, agentEmail) => {
    fetch(`${baseUrl}/api/initiate-reset-password`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ corporateId, agentName, agentEmail }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.message) {
          setInitiatedPasswordReset(true);
          toast.info(response.message);
        } else {
          toast.error(response.Error);
        }
      })
      .catch((error) => {
        toast.error("Unable to initiate password reset");
      });
  };

  const apiCallConfirmPasswordReset = (newPassword, alphaNumericOtp) => {
    fetch(`${baseUrl}/api/confirm-password-reset`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ newPassword, alphaNumericOtp }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.message) {
          toast.success(response.message);
        } else if (response.Error) {
          toast.error(response.Error);
        } else {
          toast.error("Unable to confirm password reset");
        }
      })
      .catch((error) => {
        toast.error(error.Error);
        setIsLoading(false);
      });
  };

  const apiCallSaveWabCreds = (
    wabPhoneNumber,
    wabPhoneNumberId,
    wabAccountId,
    wabAccessToken,
    wabAppId,
    wabSignupCode
  ) => {
    setIsLoading(true);

    return fetch(`${baseUrl}/api/save-wab-api-creds`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        wabPhoneNumber,
        wabPhoneNumberId,
        wabAccountId,
        wabAccessToken,
        wabAppId,
        wabSignupCode,
      }),
    })
      .then((res) => {
        return res.json().then((data) => {
          if (res.ok && data.message) {
            toast.success(data.message);
            loginCheck();
            return Promise.resolve({ message: data.message });
          } else if (data.Error) {
            toast.error(data.Error);
            return Promise.resolve({ error: data.Error });
          } else {
            toast.warn("Request Status Unknown");
            return Promise.resolve({ error: "Request Status Unknown" });
          }
        });
      })
      .catch((error) => {
        toast.error("An error occurred: " + error);
        return Promise.resolve({ error: "An error occurred: " + error });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const apiCallGetUserAgents = () => {
    setIsLoading(true);
    fetch(`${baseUrl}/api/get-user-agents`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response?.Error) {
          toast.error(response.Error);
        } else if (response?.message) {
          toast.info(response.message);
        } else if (response?.data) {
          setUserAgentsList(response.data);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        toast.error("Failed to load user agents");
        setIsLoading(false);
      });
  };

  const apiCallUpdateAgent = (agentId, infoType, infoValue) => {
    setIsLoading(true);
    fetch(`${baseUrl}/api/update-user-agent`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ agentId, infoType, infoValue }),
    })
      .then((res) => res.json())
      .then((response) => {
        apiCallGetUserAgents();
        toast.success(response?.message);
        setIsLoading(false);
      })
      .catch((error) => {
        toast.error(error.Error);
        setIsLoading(false);
      });
  };

  const apiCallCreateAgent = async (
    agentUsername,
    agentPassword,
    agentFullName,
    agentEmail,
    agentType
  ) => {
    setIsLoading(true);
    const response = await fetch(`${baseUrl}/api/create-user-agent`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        agentUsername,
        agentPassword,
        agentFullName,
        agentEmail,
        agentType,
      }),
    });

    const result = await response.json();

    if (result?.Error) {
      toast.error(result.Error);
      setIsLoading(false);
      return false;
    } else {
      toast.success(result?.message);
      apiCallGetUserAgents();
      setIsLoading(false);
      return true;
    }
  };

  const apiCallDeleteAgent = (agentId) => {
    setIsLoading(true);
    fetch(`${baseUrl}/api/delete-user-agent`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ agentId: agentId }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.Error) {
          toast.error(response.Error);
        } else {
          toast.success(response?.message);
          apiCallGetUserAgents();
          setIsLoading(false);
        }
      })
      .catch((error) => {
        toast.error(error.Error);
        setIsLoading(false);
      });
  };

  const apiConversationQuotaLimit = () => {
    fetch(`${baseUrl}/api/user-conversation-limits`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response?.Error) {
          toast.error(response.Error);
        } else {
          setUserConversationLimits(response);
        }
      })
      .catch((err) => {
        toast.error("Failed to fetch WhatsApp Creds");
      });
  };

  return (
    <UserContext.Provider
      value={{
        ipCheck,
        daypartFunction,
        requestNotificationPermission,
        loginCheck,
        apiCallLogin,
        apiCallRegister,
        isUserAdminOrSuperadmin,
        apiCallChangePassword,
        apiCallInitiateResetPassword,
        apiCallConfirmPasswordReset,
        apiCallLogout,
        apiCallSaveWabCreds,
        apiCallGetUserAgents,
        apiCallUpdateAgent,
        apiCallCreateAgent,
        apiCallDeleteAgent,
        apiConversationQuotaLimit,
        currentRegionCurr,
        registrationStatus,
        isLoading,
        isLoggedIn,
        currentUserInfo,
        daypart,
        initiatedPasswordReset,
        userAgentsList,
        userConversationLimits,
        hideSidebar,
        setCurrentRegionCurr,
        setRegistrationStatus,
        setIsLoading,
        setIsLoggedIn,
        setCurrentUserInfo,
        setDaypart,
        setInitiatedPasswordReset,
        setHideSidebar,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => useContext(UserContext);
