import React from "react";
import { FaExternalLinkAlt } from "react-icons/fa";
import {
  Card,
  CardBody,
  CardFooter,
  Typography,
  Button,
} from "@material-tailwind/react";

import { wabConfig } from "../../config/wabConfig";

import { SeoTags } from "..";

const WhatsappManager = () => {
  return (
    <main className="w-full mt-5 mb-10 lg:my-1 space-y-5">
      <SeoTags title="WhatsApp Manager ‣ WaBM" />

      <div className="w-full space-y-2">
        <h1 className="text-2xl">WhatsApp Manager</h1>
        <h3>
          Following are the options to manage WhatsApp directly on Meta Business
          Suite
        </h3>
        <p className="mt-5 text-sm text-blue-600">
          Please note that you must be logged into the Facebook or Meta Business
          Suite where your WhatsApp Business API has been configured.
        </p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5">
        <Card>
          <CardBody>
            <Typography variant="h5" color="blue-gray" className="mb-2">
              Overview
            </Typography>
            <Typography>
              Get info about WhatsApp Account on WhatsApp Manager's Overview
            </Typography>
          </CardBody>
          <CardFooter className="pt-0">
            <a
              href={wabConfig.waManager.overview}
              target="_blank"
              rel="noopener noreferrer"
              className="inline-block"
            >
              <Button
                size="sm"
                variant="text"
                className="flex items-center gap-2"
              >
                Visit
                <FaExternalLinkAlt />
              </Button>
            </a>
          </CardFooter>
        </Card>
        <Card>
          <CardBody>
            <Typography variant="h5" color="blue-gray" className="mb-2">
              Phone Numbers
            </Typography>
            <Typography>
              Get info about registered Phone Numbers on WhatsApp Business API
            </Typography>
          </CardBody>
          <CardFooter className="pt-0">
            <a
              href={wabConfig.waManager.phoneNumbers}
              target="_blank"
              rel="noopener noreferrer"
              className="inline-block"
            >
              <Button
                size="sm"
                variant="text"
                className="flex items-center gap-2"
              >
                Visit
                <FaExternalLinkAlt />
              </Button>
            </a>
          </CardFooter>
        </Card>
        <Card>
          <CardBody>
            <Typography variant="h5" color="blue-gray" className="mb-2">
              Insights
            </Typography>
            <Typography>Get WhatsApp API usage insights</Typography>
          </CardBody>
          <CardFooter className="pt-0">
            <a
              href={wabConfig.waManager.insights}
              target="_blank"
              rel="noopener noreferrer"
              className="inline-block"
            >
              <Button
                size="sm"
                variant="text"
                className="flex items-center gap-2"
              >
                Visit
                <FaExternalLinkAlt />
              </Button>
            </a>
          </CardFooter>
        </Card>
        <Card>
          <CardBody>
            <Typography variant="h5" color="blue-gray" className="mb-2">
              Payment Methods
            </Typography>
            <Typography>
              Add or Manage Payment Methods to be used for WA API Billing
            </Typography>
          </CardBody>
          <CardFooter className="pt-0">
            <a
              href={wabConfig.waManager.paymentMethods}
              target="_blank"
              rel="noopener noreferrer"
              className="inline-block"
            >
              <Button
                size="sm"
                variant="text"
                className="flex items-center gap-2"
              >
                Visit
                <FaExternalLinkAlt />
              </Button>
            </a>
          </CardFooter>
        </Card>

        <Card>
          <CardBody>
            <Typography variant="h5" color="blue-gray" className="mb-2">
              Message Templates
            </Typography>
            <Typography>
              Create, Edit, Monitor and Manage Message Templates on WhatsApp
              Manager
            </Typography>
          </CardBody>
          <CardFooter className="pt-0">
            <a
              href={wabConfig.waManager.messageTemplates}
              target="_blank"
              rel="noopener noreferrer"
              className="inline-block"
            >
              <Button
                size="sm"
                variant="text"
                className="flex items-center gap-2"
              >
                Visit
                <FaExternalLinkAlt />
              </Button>
            </a>
          </CardFooter>
        </Card>
        <Card>
          <CardBody>
            <Typography variant="h5" color="blue-gray" className="mb-2">
              Template Library
            </Typography>
            <Typography>
              Get Examples of Message Templates to reach your customers
              professionally
            </Typography>
          </CardBody>
          <CardFooter className="pt-0">
            <a
              href={wabConfig.waManager.templateLibrary}
              target="_blank"
              rel="noopener noreferrer"
              className="inline-block"
            >
              <Button
                size="sm"
                variant="text"
                className="flex items-center gap-2"
              >
                Visit
                <FaExternalLinkAlt />
              </Button>
            </a>
          </CardFooter>
        </Card>
      </div>
    </main>
  );
};

export default WhatsappManager;
