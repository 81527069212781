export const wabConfig = {
  waManager: {
    overview: "https://business.facebook.com/latest/whatsapp_manager/overview",
    phoneNumbers:
      "https://business.facebook.com/latest/whatsapp_manager/phone_numbers",
    insights: "https://business.facebook.com/latest/whatsapp_manager/insights",
    paymentMethods: "https://business.facebook.com/billing_hub/payment_methods",

    messageTemplates:
      "https://business.facebook.com/latest/whatsapp_manager/message_templates",
    templateLibrary:
      "https://business.facebook.com/latest/whatsapp_manager/template_library",
  },
};
