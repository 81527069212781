import { React, useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { AiFillSchedule } from "react-icons/ai";
import { MdDescription } from "react-icons/md";
import * as XLSX from "xlsx";
import {
  Button,
  Select,
  Option,
  Dialog,
  DialogHeader,
  DialogBody,
  // Checkbox,
  // List,
  // ListItem,
  // ListItemPrefix,
  // Typography,
} from "@material-tailwind/react";

import { colorsConfig } from "../../config/colorsConfig";
import { useContactsContext } from "../../contexts/ContactsContextProvider";
import { useWhatsappContext } from "../../contexts/WhatsappContextProvider";
import { Loader } from "..";
import { isoTimestampToLocal } from "../utils/timeStampConversion";
// import { filterData } from "../../data/filterData.js";

const ViewTrackingContactsTable = ({ scheduleStatus, jobId, selectedJob }) => {
  const {
    scheduledCampaignsTrackingData,
    isLoading,
    contactGroups,
    apiCallAssignContactGroups,
    apiCallFetchContactGroups,
  } = useContactsContext();
  const { resendSelectMessages, isProcessing } = useWhatsappContext();

  const [isVisibleSendButton, setIsVisibleSendButton] = useState(false);
  const [selectedContact, setSelectedContact] = useState([]);
  const [selectedContactToAddGroup, setSelectedContactToAddGroup] = useState(
    []
  );
  const [targetContactGroup, setTargetContactGroup] = useState("");
  const [openGroupCreationDialog, setOpenGroupCreationDialog] = useState(false);

  const assignContactGroupsOnSubmit = async (event) => {
    event.preventDefault();

    if (contactGroups.filter((x) => x.groupName === targetContactGroup)) {
      const result = await apiCallAssignContactGroups(
        selectedContactToAddGroup,
        targetContactGroup
      );

      if (result?.success) {
        setOpenGroupCreationDialog(false);
        setIsVisibleSendButton(false);
      }
    }
  };

  let selectedFailedContact = [];
  let selectedContactToGroup = [];
  const conditionalRowStyles = [
    {
      when: (row) => row?.MessageStatusFromWhatsapp === "read",
      style: {
        color: "green",
      },
    },
    {
      when: (row) => row?.MessageStatusFromWhatsapp === "delivered",
      style: {
        color: "blue",
      },
    },
    {
      when: (row) => row?.MessageStatusFromWhatsapp === "failed",
      style: {
        color: "red",
      },
    },
  ];

  // const handleMassSelect = (status) => {
  //   const updatedData = scheduledCampaignsTrackingData.map((row) => {
  //     if (selectedContact.includes(row)) {
  //       return { ...row, MessageStatusFromWhatsapp: status };
  //     }
  //     return row;
  //   });
  // };

  const scheduleTrackingTableColumns = [
    {
      name: "Contact Number",
      selector: (row) => row?.ContactNumber,
      minWidth: "180px",
    },
    {
      name: "Contact Name",
      selector: (row) => row?.contactName,
      sortable: true,
      minWidth: "250px",
    },
    {
      name: "Email",
      selector: (row) => row?.email,
      center: true,
      minWidth: "100px",
    },
    {
      name: "Company",
      selector: (row) => row?.companyName,
      minWidth: "200px",
    },
    {
      name: "Send Status",
      selector: (row) => row?.MessageStatus,
      center: true,
      sortable: true,
      minWidth: "150px",
    },
    // {
    //   name: (
    //     <div>
    //       Message Status
    //       <select
    //         onChange={(e) => handleMassSelect(e.target.value)}
    //         style={{ marginLeft: "10px" }}
    //       >
    //         <option value="" disabled selected>
    //           Mass Update
    //         </option>
    //         <option value="failed">Failed</option>
    //         <option value="read">Read</option>
    //         <option value="delivered">Delivered</option>
    //         <option value="sent">Sent</option>
    //       </select>
    //     </div>
    //   ),
    //   selector: (row) => row?.MessageStatusFromWhatsapp,
    //   center: true,
    //   sortable: true,
    //   minWidth: "150px",
    // },
    {
      name: "Message Status",
      selector: (row) => row?.MessageStatusFromWhatsapp,
      center: true,
      sortable: true,
      minWidth: "150px",
      conditionalCellStyles: conditionalRowStyles,
    },
    {
      name: "Code",
      selector: (row) => row?.failed_reason_error_code,
      center: true,
      minWidth: "150px",
    },
    {
      name: "Remarks",
      selector: (row) => row?.failed_reason,
      center: false,
      wrap: true,
      minWidth: "250px",
    },
  ];

  const handleChangeOnSelect = ({ selectedRows }) => {
    selectedFailedContact = [];
    selectedContactToGroup = [];

    selectedRows?.map((x) => selectedFailedContact.push(x?.ContactNumber));
    selectedRows?.map((x) =>
      selectedContactToGroup.push(
        JSON.stringify({
          phoneNo: x?.phone_number_c,
          countryCode: x?.country_code_si,
        })
      )
    );

    if (selectedFailedContact.length > 0) {
      setSelectedContact(selectedFailedContact);
      setSelectedContactToAddGroup(selectedContactToGroup);
      setIsVisibleSendButton(true);
    } else {
      setSelectedContact([]);
      setIsVisibleSendButton(false);
    }
  };

  const handleResend = async (e) => {
    e.preventDefault();
    await resendSelectMessages({
      scheduleId: jobId,
      selectedContacts: selectedContact,
      scheduleStatus: scheduleStatus,
    });
  };

  const downloadAllContactsOnClick = async () => {
    let contactArray = [];
    // eslint-disable-next-line array-callback-return
    scheduledCampaignsTrackingData?.map((contact) => {
      contactArray.push({
        "Contact Number": contact?.ContactNumber,
        "Contact Name": contact?.contactName,
        Email: contact?.email,
        Company: contact?.companyName,
        City: contact?.city,
        "Send Status": contact?.MessageStatus,
        "Message Status": contact?.MessageStatusFromWhatsapp,
      });
    });

    const dataForForDownload = contactArray;
    const worksheet = XLSX.utils.json_to_sheet(dataForForDownload);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(
      workbook,
      `Campaign Data - ${selectedJob?.scheduleName} - ${isoTimestampToLocal(
        selectedJob?.scheduleDateTime
      )}.xlsx`
    );
  };

  useEffect(() => {
    apiCallFetchContactGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main className="p-5 space-y-2">
      <h3 className="wabm-font-style-2 text-white">Message Tracking Status</h3>
      <div className="flex justify-between gap-3">
        <div className="flex gap-5">
          <div className="space-y-3">
            <span className="flex items-center gap-2 text-white">
              <AiFillSchedule size={24} />
              <h4 className="text-xl font-bold">{selectedJob?.scheduleName}</h4>
            </span>
            {selectedJob?.scheduleDescription && (
              <span className="flex items-center gap-2 text-white">
                <MdDescription size={16} />
                <p className="text-white">{selectedJob?.scheduleDescription}</p>
              </span>
            )}
            <Button
              color="green"
              size="sm"
              onClick={() => downloadAllContactsOnClick()}
              className="w-fit px-5 whitespace-nowrap"
            >
              Download Report
            </Button>
          </div>
          <div className="space-y-3 text-white text-sm">
            <p>
              Created : {isoTimestampToLocal(selectedJob?.scheduleCreationDate)}
            </p>
            <p>
              Scheduled : {isoTimestampToLocal(selectedJob?.scheduleDateTime)}
            </p>
            <p>Status : {selectedJob?.scheduleStatus}</p>
          </div>
        </div>

        <div className="bg-white h-full self-end gap-y-2 rounded-md">
          {isVisibleSendButton && (
            <div className="flex items-center gap-2">
              <p className="text-white">Action :</p>
              <Button
                color="yellow"
                size="sm"
                disabled={isProcessing}
                onClick={handleResend}
              >
                {isProcessing ? <span>Sending...</span> : <span>Resend</span>}
              </Button>
              <Button
                color="blue"
                size="sm"
                onClick={() => {
                  setOpenGroupCreationDialog(true);
                }}
              >
                <span>Add to contact group</span>
              </Button>
            </div>
          )}

          {/* <List className="flex-row">
            <ListItem className="p-0">
              {filterData.map((fld) => (
                <label
                  htmlFor="horizontal-list-filter"
                  className="w-full px-3 py-2 flex items-center cursor-pointer"
                >
                  <ListItemPrefix className="mr-3">
                    <Checkbox
                      name="horizontal-list-filter"
                      id="horizontal-list-filter"
                      ripple={false}
                      className="hover:before:opacity-0"
                      color={fld.color}
                      containerProps={{
                        className: "p-0",
                      }}
                    />
                  </ListItemPrefix>
                  <Typography color={fld.color} className="font-medium">
                    {fld.name}
                  </Typography>
                </label>
              ))}
            </ListItem>
          </List> */}
        </div>
      </div>

      <div className="w-full max-h-[90vh] pt-3 rounded-md overflow-auto">
        {isLoading ? (
          <Loader />
        ) : scheduledCampaignsTrackingData.length > 0 ? (
          <DataTable
            fixedHeader
            selectableRows
            onSelectedRowsChange={handleChangeOnSelect}
            columns={scheduleTrackingTableColumns}
            data={scheduledCampaignsTrackingData}
            fixedHeaderScrollHeight="75vh"
            customStyles={scheduleTableStyles}
          />
        ) : (
          <div className="space-y-3">
            <p>No Tracking data found.</p>
          </div>
        )}
      </div>

      <Dialog
        open={openGroupCreationDialog}
        handler={() => setOpenGroupCreationDialog(!openGroupCreationDialog)}
      >
        <span className="flex justify-between gap-3">
          <DialogHeader>Add Contact Group</DialogHeader>
        </span>
        <DialogBody>
          <form
            onSubmit={assignContactGroupsOnSubmit}
            className="flex flex-row md:flex-nowrap items-center gap-3"
          >
            <Select
              variant="outlined"
              label="Select Contact Group"
              color="blue"
              required
              value={targetContactGroup}
              onChange={(e) => setTargetContactGroup(e)}
              className="w-full"
            >
              {contactGroups?.map((group, index) => (
                <Option key={index} value={group?.groupName}>
                  {group?.groupName} (
                  {group?.numberOfContacts ? group.numberOfContacts : 0}{" "}
                  contacts)
                </Option>
              ))}
            </Select>
            <div className="flex flex-row items-center gap-2">
              <Button type="submit" variant="gradient" color="green">
                <span className="whitespace-nowrap">Add to Group</span>
              </Button>
            </div>
          </form>
        </DialogBody>
      </Dialog>
    </main>
  );
};

export default ViewTrackingContactsTable;

const scheduleTableStyles = {
  rows: {
    style: {},
  },
  headCells: {
    style: {
      backgroundColor: colorsConfig.wabmBlue,
      color: "#FFFFFF",
      fontSize: "0.9rem",
    },
  },
  cells: {
    style: {},
  },
};
