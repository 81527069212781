import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { Input, Button } from "@material-tailwind/react";

import { SeoTags, CustomLoader } from "..";
import { colorsConfig } from "../../config/colorsConfig";
import { useConversationContext } from "../../contexts/ConversationContextProvider";
import { convertUnixTimestampToDateTime } from "../utils/timeStampConversion";

const ManageAssignedChats = () => {
  const { convGetAssignedChats } = useConversationContext();

  const [chatAssignList, setChatAssignList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState("");

  const chatAssignTableColumn = [
    {
      name: "Contact Number",
      selector: (row) => row?._id,
      sortable: true,
      maxWidth: "15%",
    },
    {
      name: "Name",
      selector: (row) => row?.fullName,
      sortable: true,
      maxWidth: "55%",
    },
    {
      name: "Last Chat Date/Time",
      selector: (row) =>
        convertUnixTimestampToDateTime(row?.messageData?.timestamp),
      // selector: (row) => row?.messageData?.timestamp,
      sortable: true,
      maxWidth: "15%",
    },
    {
      name: "Assigned User",
      selector: (row) => {
        return (
          <div className="flex flex-row gap-2 flex-wrap">
            {row?.assignedUserName?.map((assignName) => {
              return (
                <div className="bg-indigo-400 px-2 py-1 text-sm text-white rounded-sm">
                  {assignName}
                </div>
              );
            })}
          </div>
        );
      },
      sortable: true,
      center: true,
      maxWidth: "15%",
    },
  ];

  const loadChatAssign = async () => {
    setIsLoading(true);
    const assignedChat = await convGetAssignedChats(searchText);
    setChatAssignList(assignedChat);
    setIsLoading(false);
  };

  const loadChatAssignOnSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const assignedChat = await convGetAssignedChats(searchText);
    setChatAssignList(assignedChat);
    setIsLoading(false);
  };

  useEffect(() => {
    loadChatAssign();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="w-full my-10 lg:my-2">
      {isLoading && <CustomLoader />}
      <SeoTags title="Assigned Chats ‣ WaBM" />

      <main className="w-full mt-5 mb-10 lg:my-1 space-y-5">
        <div className="w-full space-y-2">
          <h1 className="text-2xl">Monitor Assigned Chats</h1>
          <h3>These are the details about all your assigned chats.</h3>
        </div>
        <div className="wbm-card-3 space-y-3">
          <form
            onSubmit={loadChatAssignOnSubmit}
            className="relative lg:w-2/4 space-3"
          >
            <Input
              type="text"
              placeholder="Search by customer/client's contact number"
              label="Search Contact Name"
              onChange={(e) => setSearchText(e.target.value)}
              className="pr-10"
              containerProps={{
                className: "min-w-0",
              }}
            />
            <Button
              type="submit"
              size="sm"
              color="green"
              className="!absolute top-1 right-1 rounded"
            >
              Search
            </Button>
          </form>

          <div className="rounded-md overflow-auto">
            <DataTable
              columns={chatAssignTableColumn}
              data={chatAssignList}
              customStyles={chatAssignTableStyles}
              fixedHeader
              fixedHeaderScrollHeight="60vh"
            />
          </div>
        </div>
      </main>
    </div>
  );
};

export default ManageAssignedChats;

const chatAssignTableStyles = {
  rows: {
    style: {},
  },
  headCells: {
    style: {
      backgroundColor: colorsConfig.mediumGrey,
      color: "#FFFFFF",
      fontSize: "0.9rem",
    },
  },
  cells: {
    style: {},
  },
};
