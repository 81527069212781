import React from "react";
import { NavLink } from "react-router-dom";
import { FaPowerOff } from "react-icons/fa";
import { IoLogoWhatsapp } from "react-icons/io";
import { IoSettingsSharp, IoBook } from "react-icons/io5";
import { MdPayments, MdNotificationsActive } from "react-icons/md";
import { TbPlugConnected } from "react-icons/tb";
import {
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Button,
} from "@material-tailwind/react";

import { contactLinks } from "../config/appConfig";
import { useUserContext } from "../contexts/UserContextProvider";
import wabmLogo from "../images/logo/WaBM-192px.png";

const DashboardNav = ({ isSocketConnected }) => {
  const { apiCallLogout, currentUserInfo, isUserAdminOrSuperadmin } =
    useUserContext();

  return (
    <div className="bg-black w-full px-3 py-1 flex flex-wrap lg:flex-nowrap justify-between items-center gap-5 text-white z-50">
      <div className="flex items-center gap-3">
        <span className="flex items-center gap-2">
          <img src={wabmLogo} className="w-10" alt="wabm" />
          <p>WaBM</p>
        </span>
        <TbPlugConnected
          title={`Message receiver ${
            isSocketConnected ? "connected" : "disconnected"
          }`}
          size="1.5rem"
          className={isSocketConnected ? "text-green-500" : "text-red-600"}
        />
      </div>

      <a
        href={contactLinks.supportWhatsapp}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="my-smooth-transition-1 w-full flex items-center gap-2 text-white hover:text-whatsAppColor2">
          <IoLogoWhatsapp size="1.5rem" />
          <p>WhatsApp Support ⇗</p>
        </div>
      </a>

      <div className="flex items-center gap-3">
        <NavLink
          to="/dashboard/notifications"
          title={"Notifications"}
          className={({ isActive }) =>
            `my-smooth-transition-1 ${
              isActive ? "text-wabmBlue" : "hover:text-wabmGreen"
            }`
          }
        >
          <MdNotificationsActive size="1.5rem" />
        </NavLink>

        <Menu placement="bottom-end">
          <MenuHandler className="bg-blue-500 hover:bg-blue-600">
            <Button> Menu</Button>
          </MenuHandler>
          <MenuList>
            <MenuItem>
              <NavLink
                to="/dashboard/settings"
                className={({ isActive }) =>
                  `my-smooth-transition-1 w-full inline-flex items-center gap-3 ${
                    isActive ? "text-wabmBlue" : "hover:text-wabmGreen"
                  }`
                }
              >
                <IoSettingsSharp size="1.5rem" />
                <p>Settings</p>
              </NavLink>
            </MenuItem>

            {isUserAdminOrSuperadmin() &&
              currentUserInfo?.pricingModel === 1 && (
                <MenuItem>
                  <NavLink
                    to="/dashboard/recharge"
                    className={({ isActive }) =>
                      `my-smooth-transition-1 w-full inline-flex items-center gap-3 ${
                        isActive ? "text-wabmBlue" : "hover:text-wabmGreen"
                      }`
                    }
                  >
                    <MdPayments size="1.5rem" />
                    <p>Recharge</p>
                  </NavLink>
                </MenuItem>
              )}
            {isUserAdminOrSuperadmin() &&
              currentUserInfo?.pricingModel === 0 && (
                <MenuItem>
                  <NavLink
                    to="/dashboard/subscriptions"
                    className={({ isActive }) =>
                      `my-smooth-transition-1 w-full inline-flex items-center gap-3 ${
                        isActive ? "text-wabmBlue" : "hover:text-wabmGreen"
                      }`
                    }
                  >
                    <MdPayments size="1.5rem" />
                    <p>Subscriptions</p>
                  </NavLink>
                </MenuItem>
              )}

            <MenuItem>
              <NavLink
                to="/faq"
                className={({ isActive }) =>
                  `my-smooth-transition-1 w-full inline-flex items-center gap-3 ${
                    isActive ? "text-wabmBlue" : "hover:text-wabmGreen"
                  }`
                }
              >
                <IoBook size="1.5rem" />
                <p>FAQs</p>
              </NavLink>
            </MenuItem>

            <hr className="my-3" />

            <MenuItem>
              <button
                onClick={apiCallLogout}
                className="my-smooth-transition-1 w-full inline-flex items-center gap-3 text-red-600 hover:text-red-500"
              >
                <FaPowerOff size="1.5rem" />
                <p>Log Out</p>
              </button>
            </MenuItem>
          </MenuList>
        </Menu>

        {/* <NavLink
          to="/dashboard/addons"
          title={"Subscription Add-ons"}
          className={({ isActive }) =>
            isActive ? "my-smooth-transition-1 text-wabmBlue" : "my-smooth-transition-1 w-full inline-flex items-center gap-3 hover:text-wabmBlue"
          }
        >
          <span className="w-full flex gap-2">
            <SiPacker size="1.5rem" />
            {<h3 className="hidden md:block">Add-ons</h3>}
            {!currentUserInfo?.isActiveSubscription && <FaLock size={10} />}
          </span>
        </NavLink> */}
      </div>
    </div>
  );
};

export default DashboardNav;
