import React, { useState } from "react";
import { Input } from "@material-tailwind/react";
import { toast } from "react-toastify";

import { ChangePassword, SeoTags } from "..";
import { useWhatsappContext } from "../../contexts/WhatsappContextProvider";
import { useUserContext } from "../../contexts/UserContextProvider";
import { aboutApp } from "../../config/appConfig.js";
import { phoneNumberSanitation } from "../utils/inputSanitation.js";

const Settings = () => {
  const { wabInfo, apiRegisterPhoneNo, apiRegisterWebhook, isLoading } =
    useWhatsappContext();
  const { currentUserInfo, isUserAdminOrSuperadmin } = useUserContext();
  const [pin, setPin] = useState("");
  const [isChangePassword, setIsChangePassword] = useState(false);

  const isChangePasswordCallback = (value) => {
    setIsChangePassword(value);
  };

  const registerPhoneNo = async () => {
    if (pin !== "" && pin?.length > 5) {
      await apiRegisterPhoneNo(pin);
    } else {
      toast.error("PIN number is required and should be 6 character");
    }
  };

  const registerWebhook = async () => {
    await apiRegisterWebhook();
  };

  return (
    <main className="w-full min-h-screen mt-5 mb-10 lg:my-1 flex flex-col gap-3">
      <SeoTags title="Settings ‣ WaBM" />

      <div className="space-y-2">
        <h1 className="text-2xl">Settings</h1>
        <h3>
          Your personalized settings for WaBM Business Manager all at one place.
        </h3>
      </div>

      <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-3">
        <div className="wbm-card-2 h-fit flex flex-col gap-3">
          <h2 className="font-Montserrat text-lg">User Information</h2>
          <span className="grid grid-cols-2 gap-3">
            <h3 className="border-r-[0.5px] border-slate-400">Corporate Id</h3>
            <p>{currentUserInfo?.corporateId}</p>
          </span>
          <span className="grid grid-cols-2 gap-3">
            <h3 className="border-r-[0.5px] border-slate-400">Agent Name</h3>
            <p>{currentUserInfo?.agentName}</p>
          </span>
          <span className="grid grid-cols-2 gap-3">
            <h3 className="border-r-[0.5px] border-slate-400">Agent Type</h3>
            <p>
              {currentUserInfo?.isAdmin === 2
                ? "Superadmin"
                : currentUserInfo?.isAdmin === 1
                ? "Admin"
                : "Agent"}
            </p>
          </span>
          <span className="grid grid-cols-2 gap-3">
            <h3 className="border-r-[0.5px] border-slate-400">
              Subscription Status
            </h3>
            <p>
              {currentUserInfo?.isActiveSubscription
                ? "Active"
                : currentUserInfo?.isTrialLapsed
                ? "Trial Active"
                : "Inactive"}
            </p>
          </span>
        </div>

        {isUserAdminOrSuperadmin() && (
          <div className="wbm-card-2 h-fit flex flex-col gap-3">
            <h2 className="font-Montserrat text-lg">
              WhatsApp Business Information
            </h2>
            <span className="grid grid-cols-2 gap-3">
              <h3 className="border-r-[0.5px] border-slate-400">
                WhatsApp Number
              </h3>
              <p>
                {wabInfo?.wabPhoneNumber === null
                  ? "-"
                  : "+" + wabInfo.wabPhoneNumber}
              </p>
            </span>
            <span className="grid grid-cols-2 gap-3">
              <h3 className="border-r-[0.5px] border-slate-400">Meta App ID</h3>
              <p>{wabInfo?.appId}</p>
            </span>
            <span className="grid grid-cols-2 gap-3">
              <h3 className="border-r-[0.5px] border-slate-400">Account ID</h3>
              <p>{wabInfo?.wabAccountId}</p>
            </span>
            <span className="grid grid-cols-2 gap-3">
              <h3 className="border-r-[0.5px] border-slate-400">
                Phone Number ID
              </h3>
              <p>{wabInfo?.wabPhoneNumberId}</p>
            </span>
            <span className="grid grid-cols-2 gap-3">
              <h3 className="border-r-[0.5px] border-slate-400">
                WhatsApp Business API
              </h3>
              <p>
                {currentUserInfo?.mandatoryWabApiCreds
                  ? "Configured"
                  : "Not Configured"}
              </p>
            </span>
          </div>
        )}

        <div className="wbm-card-2 h-fit space-y-2">
          <h3 className="font-Montserrat text-lg">Change Password</h3>
          <p>Set a new secure password for your WaBM Agent Account</p>
          {isChangePassword ? (
            <ChangePassword
              isChangePasswordCallback={isChangePasswordCallback}
            />
          ) : (
            <button
              onClick={() => setIsChangePassword(true)}
              className="my-button-style-1"
            >
              Change Password
            </button>
          )}
        </div>
        {currentUserInfo?.isAdmin === 2 && (
          <div className="wbm-card-2 h-fit space-y-2">
            <h3 className="font-Montserrat text-lg">Register / Re-Register</h3>
            <p>
              To register your WA Business phone no. kindly provide the below
              details
            </p>
            <div className="flex flex-row justify-start gap-2">
              <Input
                type="text"
                variant="outlined"
                label="Enter PIN"
                maxLength="6"
                required
                value={pin}
                onChange={(e) => setPin(phoneNumberSanitation(e.target.value))}
              />
              <button
                disabled={isLoading}
                onClick={() => registerPhoneNo()}
                className="my-button-style-1"
              >
                Register
              </button>
            </div>
          </div>
        )}
        {currentUserInfo?.isAdmin === 2 &&
          currentUserInfo?.socketServerUrl === "" && (
            <div className="wbm-card-2 h-fit space-y-2">
              <h3 className="font-Montserrat text-lg">Register webhook</h3>
              <p>Register your webhook with your WABM partner</p>
              <button
                disabled={isLoading}
                onClick={() => registerWebhook()}
                className="my-button-style-1"
              >
                Register
              </button>
            </div>
          )}
      </div>

      <div className="my-5 text-sm text-slate-600">
        <p>{aboutApp.version}</p>
        <p>
          Please note that WaBM is not a part of or affiliated to WhatsApp,
          Facebook, Meta or any of its subsidiaries.
        </p>
      </div>
    </main>
  );
};

export default Settings;
