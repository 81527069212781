import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import * as Icons from "react-icons/fa";
import { useConversationContext } from "../contexts/ConversationContextProvider";
import { IoPersonCircleSharp } from "react-icons/io5";

const ScanMe = () => {
  const { profileName } = useParams();
  const { getScanMeLinkByProfileName, getScanMeProfileName } =
    useConversationContext();

  const [scanMeLinks, setScanMeLinks] = useState([]);
  const [scanMeProfileName, setScanMeProfileName] = useState("");

  const loadScanMeLinks = async () => {
    const response = await getScanMeLinkByProfileName(profileName);
    setScanMeLinks(response);
  };

  const loadScanMeProfileName = async () => {
    const response = await getScanMeProfileName();
    setScanMeProfileName(response?.[0]?.profileName);
  };

  const DisplayIcon = ({ selectedIcon }) => {
    const IconComponent = Icons[selectedIcon];

    return <IconComponent size={30} className="text-blue-700" />;
  };

  useEffect(() => {
    loadScanMeLinks();
    loadScanMeProfileName();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="w-full h-[98vh] bg-slate-200 shadow-lg p-5">
        <div className="flex flex-col justify-center items-center">
          <div className="h-32 w-32 rounded-full self-center mb-3">
            <IoPersonCircleSharp className="bg-white w-32 h-auto  text-wabmBlue drop-shadow-md rounded-full" />
          </div>
          <div className="self-center text-2xl mb-8 text-blue-800 font-bold">
            {scanMeProfileName}
          </div>
          {Array.isArray(scanMeLinks) &&
            scanMeLinks?.map((item, index) => (
              <div
                key={index}
                className="w-[30%] flex-col justify-center items-center gap-2 mb-2 pr-8 pl-4 rounded-[20px] bg-white hover:cursor-pointer hover:border-2 hover:border-blue-500"
              >
                <div className="flex flex-row justify-between gap-4">
                  <a
                    href={item?.pageLink}
                    className="flex flex-row gap-7 h-20 justify-between items-center hover:cursor-pointer hover:text-white"
                  >
                    <DisplayIcon
                      selectedIcon={item?.thumbnailLink}
                    ></DisplayIcon>
                    <div className="text-lg text-blue-800">
                      {item?.pageTitle}
                    </div>
                  </a>
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default ScanMe;
