import React, { useState } from "react";
import { toast } from "react-toastify";
import { RiTimerFlashFill } from "react-icons/ri";
import {
  Typography,
  Input,
  Select as MTSelect,
  Option,
  Button,
} from "@material-tailwind/react";
import Select from "react-select";

import { useWhatsappContext } from "../../contexts/WhatsappContextProvider";
import {
  uploadMediaOnAWS,
  EnumFileUploadStatus,
} from "../../helpers/AWSService.js";

const CampaignScheduler = ({
  contactType,
  contactDetail,
  selectedTemplate,
  parametersToBeSent,
  bodyVal,
  headerVal,
  selectedMedia,
}) => {
  const { scheduleGroupCampaign, setToShowScheduleSettings, isLoading } =
    useWhatsappContext();

  const addHoursToCurrentDateTime = (hours) => {
    let date = new Date();
    date.setHours(date.getHours() + hours);
    date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
    return date.toISOString().substring(0, 16);
  };

  const [scheduleName, setScheduleName] = useState("");
  const [scheduleDescription, setScheduleDescription] = useState("");
  const [scheduleType, setScheduleType] = useState("oneTime");
  const [frequencyType, setFrequencyType] = useState("daily");
  const [selectedDateTime, setSelectedDateTime] = useState(
    addHoursToCurrentDateTime(0)
  );
  const [weekDayValue, setWeekDayValue] = useState(null);

  const weekDay = [
    {
      value: 0,
      label: "Sunday",
    },
    {
      value: 1,
      label: "Monday",
    },
    {
      value: 2,
      label: "Tuesday",
    },
    {
      value: 3,
      label: "Wednesday",
    },
    {
      value: 4,
      label: "Thursday",
    },
    {
      value: 5,
      label: "Friday",
    },
    {
      value: 6,
      label: "Saturday",
    },
  ];

  const defaultInputValue = {
    frequencyType: "",
    startDateTime: "",
    endDateTime: "",
    scheduleInterval: "",
    intervalType: "",
  };

  const [inputData, setInputData] = useState(defaultInputValue);
  const handleInputChange = (e, controlName) => {
    if (controlName === "intervalType" || controlName === "frequencyType") {
      const value = e;
      setInputData((prevData) => ({
        ...prevData,
        [controlName]: value,
      }));
    }
    if (
      controlName === "startDateTime" ||
      controlName === "endDateTime" ||
      controlName === "scheduleInterval"
    ) {
      const value = e.target.value;
      setInputData((prevData) => ({
        ...prevData,
        [controlName]: value,
      }));
    }
  };
  const [monthDay, setMonthDay] = useState();
  const options = [];
  for (let i = 1; i <= 31; i++) {
    options.push({
      value: i,
      label: i,
    });
  }

  // Currently implemented for Contact Group. Modify it later to cater both
  const scheduleMessageOnSubmit = async (event) => {
    event.preventDefault();
    const mediaUploadStatus = await uploadMediaOnAWS(selectedMedia);
    if (
      mediaUploadStatus === EnumFileUploadStatus.FileUploadSuccess ||
      mediaUploadStatus === EnumFileUploadStatus.FileNotAvailable
    ) {
      let headerField = [];
      if (headerVal.length > 0) {
        headerField = headerVal;
      } else {
        headerField = ["emptyHeader"];
      }

      if (scheduleType === "oneTime") {
        const scheduleTime = new Date(selectedDateTime);
        const allowedTime = new Date();
        if (scheduleTime.getTime() > allowedTime.getTime() + 10000) {
          const frequency = {
            weekly: [],
            daily: [],
            monthly: [],
          };
          scheduleGroupCampaign(
            scheduleName,
            scheduleDescription,
            scheduleType,
            scheduleTime.toISOString().substring(0, 16),
            contactDetail,
            selectedTemplate,
            parametersToBeSent,
            headerField.concat(bodyVal),
            contactType,
            frequency
          );
          setToShowScheduleSettings(false);
        } else {
          toast.warn("Please schedule campaign atleast 10 min from now");
        }
      } else {
        const scheduleTime = new Date(inputData.startDateTime);
        const endDateTime = new Date(inputData.endDateTime);
        let dailyObject = [];
        let monthlyObject = [];
        let weeklyObject = [];
        if (frequencyType === "daily") {
          dailyObject = [
            {
              scheduleDay: "",
              isRepeat: 1,
              scheduleDateTime: scheduleTime.toISOString().substring(0, 16),
              scheduleEndTime: endDateTime.toISOString().substring(0, 16),
              scheduleInterval: inputData.scheduleInterval,
              intervalType: inputData.intervalType,
            },
          ];
        } else if (frequencyType === "weekly") {
          weeklyObject = [
            {
              scheduleDay: weekDayValue?.map((day) => day.value),
              isRepeat: 1,
              scheduleDateTime: scheduleTime.toISOString().substring(0, 16),
              scheduleEndTime: endDateTime.toISOString().substring(0, 16),
              scheduleInterval: inputData.scheduleInterval,
              intervalType: inputData.intervalType,
            },
          ];
        } else {
          monthlyObject = [
            {
              monthDay: monthDay?.map((day) => day.value),
              isRepeat: 1,
              scheduleDateTime: scheduleTime.toISOString().substring(0, 16),
              scheduleEndTime: endDateTime.toISOString().substring(0, 16),
              scheduleInterval: inputData.scheduleInterval,
              intervalType: inputData.intervalType,
            },
          ];
        }

        const frequency = {
          daily: dailyObject,
          weekly: weeklyObject,
          monthly: monthlyObject,
        };

        scheduleGroupCampaign(
          scheduleName,
          scheduleDescription,
          scheduleType,
          scheduleTime.toISOString().substring(0, 16),
          contactDetail,
          selectedTemplate,
          parametersToBeSent,
          headerField.concat(bodyVal),
          contactType,
          frequency
        );
        setToShowScheduleSettings(false);
      }
    }
  };

  const renderRecurringSchedule = () => {
    switch (frequencyType) {
      case "daily": {
        return (
          <>
            <div className="flex flex-row justify-start gap-2">
              <Input
                variant="standard"
                color="green"
                className="my-input-box-1"
                label="Start Date Time"
                type="datetime-local"
                name="scheduleDateTime"
                value={inputData.startDateTime}
                onChange={(e) => {
                  handleInputChange(e, "startDateTime");
                }}
                containerProps={{
                  className: "min-w-0",
                }}
              />
              <Input
                variant="standard"
                color="green"
                label="End Date Time"
                className="my-input-box-1"
                type="datetime-local"
                name="scheduleEndTime"
                value={inputData.endDateTime}
                onChange={(e) => {
                  handleInputChange(e, "endDateTime");
                }}
                containerProps={{
                  className: "min-w-0",
                }}
              />
            </div>
            <div className="flex flex-row justify-start gap-2 mt-3">
              <Input
                value={inputData.scheduleInterval}
                onChange={(e) => handleInputChange(e, "scheduleInterval")}
                variant="standard"
                className="my-input-box-1"
                label="Schedule Interval"
                name="scheduleInterval"
                color="green"
              />
              <MTSelect
                className="my-input-box-1"
                value={inputData.intervalType}
                onChange={(e) => {
                  handleInputChange(e, "intervalType");
                }}
                variant="standard"
                label="Interval type"
                color="green"
              >
                <Option
                  value="hour"
                  className={`hover:bg-blue-300 focus:bg-blue-300`}
                >
                  Hour
                </Option>
                <Option
                  value="minute"
                  className={`hover:bg-blue-300 focus:bg-blue-300`}
                >
                  Minute
                </Option>
              </MTSelect>
            </div>
          </>
        );
      }

      case "weekly": {
        return (
          <>
            <div className="w-full">
              <Select
                className="mb-4"
                value={weekDayValue}
                placeholder="Select week day"
                defaultValue={weekDayValue}
                closeMenuOnSelect={false}
                onChange={setWeekDayValue}
                isMulti="true"
                options={weekDay}
              />
            </div>
            <div className="flex flex-row justify-start gap-2">
              <Input
                variant="standard"
                color="green"
                className="my-input-box-1"
                label="Start Date Time"
                type="datetime-local"
                name="scheduleDateTime"
                value={inputData.startDateTime}
                onChange={(e) => {
                  handleInputChange(e, "startDateTime");
                }}
                containerProps={{
                  className: "min-w-0",
                }}
              />
              <Input
                variant="standard"
                color="green"
                label="End Date Time"
                className="my-input-box-1"
                type="datetime-local"
                name="scheduleEndTime"
                value={inputData.endDateTime}
                onChange={(e) => {
                  handleInputChange(e, "endDateTime");
                }}
                containerProps={{
                  className: "min-w-0",
                }}
              />
            </div>
            <div className="flex flex-row justify-start gap-2 mt-3">
              <Input
                value={inputData.scheduleInterval}
                onChange={(e) => handleInputChange(e, "scheduleInterval")}
                variant="standard"
                className="my-input-box-1"
                label="Schedule Interval"
                name="scheduleInterval"
                color="green"
              />
              <MTSelect
                onChange={(e) => {
                  handleInputChange(e, "intervalType");
                }}
                value={inputData?.intervalType}
                className="my-input-box-1"
                variant="standard"
                label="Interval type"
                color="green"
              >
                <Option
                  value="hour"
                  className={`hover:bg-blue-300 focus:bg-blue-300`}
                >
                  Hour
                </Option>
                <Option
                  value="minute"
                  className={`hover:bg-blue-300 focus:bg-blue-300`}
                >
                  Minute
                </Option>
              </MTSelect>
            </div>
          </>
        );
      }

      case "monthly": {
        return (
          <>
            <Select
              className="mb-4"
              value={monthDay}
              defaultValue={monthDay}
              closeMenuOnSelect={false}
              onChange={setMonthDay}
              placeholder="Select month day"
              isMulti="true"
              options={options}
            />
            <div className="flex flex-row justify-start gap-2">
              <Input
                variant="standard"
                color="green"
                className="my-input-box-1"
                label="Start Date Time"
                type="datetime-local"
                name="scheduleDateTime"
                value={inputData.startDateTime}
                onChange={(e) => {
                  handleInputChange(e, "startDateTime");
                }}
                containerProps={{
                  className: "min-w-0",
                }}
              />
              <Input
                variant="standard"
                color="green"
                label="End Date Time"
                className="my-input-box-1"
                type="datetime-local"
                name="scheduleEndTime"
                value={inputData.endDateTime}
                onChange={(e) => {
                  handleInputChange(e, "endDateTime");
                }}
                containerProps={{
                  className: "min-w-0",
                }}
              />
            </div>
            <div className="flex flex-row justify-start gap-2 mt-3">
              <Input
                value={inputData.scheduleInterval}
                onChange={(e) => handleInputChange(e, "scheduleInterval")}
                variant="standard"
                className="my-input-box-1"
                label="Schedule Interval"
                name="scheduleInterval"
                color="green"
              />
              <MTSelect
                onChange={(e) => {
                  handleInputChange(e, "intervalType");
                }}
                value={inputData?.intervalType}
                className="my-input-box-1"
                variant="standard"
                label="Interval type"
                color="green"
              >
                <Option
                  value="hour"
                  className={`hover:bg-blue-300 focus:bg-blue-300`}
                >
                  Hour
                </Option>
                <Option
                  value="minute"
                  className={`hover:bg-blue-300 focus:bg-blue-300`}
                >
                  Minute
                </Option>
              </MTSelect>
            </div>
          </>
        );
      }

      default:
        break;
    }
  };

  return (
    <>
      <form onSubmit={scheduleMessageOnSubmit} className="space-y-5">
        <Typography variant="h4" color="blue">
          Schedule Marketing Campaign
        </Typography>
        <div className="space-y-3">
          <Input
            label="Schedule Name"
            variant="standard"
            color="blue"
            labelProps={{
              className: "text-slate-800",
            }}
            required
            value={scheduleName}
            onChange={(e) => setScheduleName(e.target.value)}
            className="my-input-box-1"
          />
          <Input
            label="Schedule Description"
            variant="standard"
            color="blue"
            labelProps={{
              className: "text-slate-800",
            }}
            value={scheduleDescription}
            onChange={(e) => setScheduleDescription(e.target.value)}
            className="my-input-box-1"
          />

          <MTSelect
            label="Schedule Type"
            variant="standard"
            color="blue"
            required
            value={scheduleType}
            onChange={(e) => setScheduleType(e)}
            className="my-input-box-1"
          >
            <Option
              value="oneTime"
              className={`hover:bg-blue-300 focus:bg-blue-300`}
            >
              One Time
            </Option>
            <Option
              value="recurring"
              className={`hover:bg-blue-300 focus:bg-blue-300`}
            >
              Recurring
            </Option>
          </MTSelect>

          {scheduleType === "oneTime" ? (
            <div className="space-y-5">
              <span className="grid grid-cols-2 gap-3">
                <p className="border-r border-slate-400">Schedule Time</p>
                <input
                  type="datetime-local"
                  value={selectedDateTime}
                  onChange={(event) => setSelectedDateTime(event.target.value)}
                  min={addHoursToCurrentDateTime(0.25)}
                  max={addHoursToCurrentDateTime(1080)}
                  className="my-input-style-1"
                />
              </span>
              <Button
                type="submit"
                variant="gradient"
                color="green"
                disabled={isLoading}
                className="inline-flex items-center gap-2"
              >
                <span>Confirm Schedule</span>
                <RiTimerFlashFill />
              </Button>
            </div>
          ) : (
            <>
              <div className="flex flex-col justify-start gap-2 p-4 border-2 border-green-600 rounded-md">
                <MTSelect
                  label="Frequency Type"
                  variant="standard"
                  color="blue"
                  required
                  value={frequencyType}
                  onChange={(e) => {
                    handleInputChange(e, "frequencyType");
                    setFrequencyType(e);
                  }}
                  className="my-input-box-1"
                >
                  <Option
                    value="daily"
                    className={`hover:bg-blue-300 focus:bg-blue-300`}
                  >
                    Daily
                  </Option>
                  <Option
                    value="weekly"
                    className={`hover:bg-blue-300 focus:bg-blue-300`}
                  >
                    Weekly
                  </Option>
                  <Option
                    value="monthly"
                    className={`hover:bg-blue-300 focus:bg-blue-300`}
                  >
                    Monthly
                  </Option>
                </MTSelect>
                <div className="mt-3 mb-2">{renderRecurringSchedule()}</div>
                <Button
                  type="submit"
                  variant="gradient"
                  color="green"
                  disabled={isLoading}
                  className="inline-flex items-center gap-2 w-[25%]"
                >
                  <span>Confirm Schedule</span>
                  <RiTimerFlashFill />
                </Button>
              </div>
            </>
          )}
        </div>
      </form>
    </>
  );
};

export default CampaignScheduler;
