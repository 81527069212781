export const contactLabels = [
  {
    label: "New Lead",
    labelKey: "new_lead",
    labelColor: "#56ccf2",
  },
  {
    label: "Hot Lead",
    labelKey: "hot_lead",
    labelColor: "#f12711",
  },
  {
    label: "Warm Lead",
    labelKey: "warm_lead",
    labelColor: "#FF4B2B",
  },
  {
    label: "Cold Lead",
    labelKey: "cold_lead",
    labelColor: "#6DD5FA",
  },
  {
    label: "Important",
    labelKey: "important",
    labelColor: "#fffc00",
  },
  {
    label: "Existing Client/Customer",
    labelKey: "existing_client",
    labelColor: "#ffe000",
  },
  {
    label: "Not Interested",
    labelKey: "not_interested",
    labelColor: "#DBDBDB",
  },
  {
    label: "Send Details",
    labelKey: "send_details",
    labelColor: "#92fe98",
  },
  {
    label: "Send Quotation",
    labelKey: "send_quotation",
    labelColor: "#66ff00",
  },
  {
    label: "Send Invoice",
    labelKey: "send_invoice",
    labelColor: "#1fa2ff",
  },
  {
    label: "Spam",
    labelKey: "spam",
    labelColor: "#bdc3c7",
  },
];
