export const appSettings = {
  serverBaseUrl: process.env.REACT_APP_SERVER_BASE_URL,
  emailjsServiceId: process.env.REACT_APP_EMAILJS_SERVICE_ID,
  emailjsTemplateId: process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
  emailjsPublicKey: process.env.REACT_APP_EMAILJS_PUBLIC_KEY,

  facebookAppId: process.env.REACT_APP_FACEBOOK_APP_ID,
  facebookConfigId: process.env.REACT_APP_FACEBOOK_CONFIG_ID,
};

export const aboutApp = {
  // website: "https://wabm.in",
  website: "http://localhost:3000",
  version: "Copyright © WaBM 2025 | Version: 25.01.15",
};

export const uploadLimits = {
  image: 5 * 1024 * 1024, // 5 megabytes in bytes
  video: 16 * 1024 * 1024, // 16 megabytes in bytes
  audio: 16 * 1024 * 1024, // 16 megabytes in bytes
  document: 100 * 1024 * 1024, // 100 megabytes in bytes
};

export const chatRefreshTime = {
  chatContacts: "5000",
  chatMessages: "5000",
};

export const contactLinks = {
  twitter: "https://twitter.com/wabm_in/",
  twitterUsername: "@wabm_in",
  instagram: "https://www.instagram.com/wabm.in/",
  instagramUsername: "@wabm.in",
  linkedin: "https://www.linkedin.com/showcase/wabm",
  linkedinUsername: "/showcase/wabm",

  salesWhatsapp: "https://api.whatsapp.com/send?phone=919718665000",
  supportWhatsapp: "https://api.whatsapp.com/send?phone=919718665000",

  salesMail: "hi@wabm.in",
  supportMail: "support@wabm.in",
};

export const contactUploadConfig = {
  maximumUploadContact: 30000,
  maximumUploadError: 100,
  defaultCountyCode: 91,
};
