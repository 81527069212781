import { React } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { AiTwotoneShop } from "react-icons/ai";
import { BsCaretLeftSquareFill, BsCaretRightSquareFill } from "react-icons/bs";
import {
  FaLock,
  // FaPrayingHands,
  // FaQrcode,
  FaReplyAll,
} from "react-icons/fa";
import { GiOpenBook } from "react-icons/gi";
import { HiTemplate } from "react-icons/hi";
import { IoMdChatbubbles, IoLogoWhatsapp } from "react-icons/io";
import {
  MdMarkChatRead,
  MdPermContactCalendar,
  MdMonitorHeart,
  MdQuickreply,
} from "react-icons/md";
import { PiUsersFill } from "react-icons/pi";
import { RiMailSendFill, RiTimer2Fill } from "react-icons/ri";

import { useUserContext } from "../contexts/UserContextProvider";
import { useConversationContext } from "../contexts/ConversationContextProvider";

const Sidebar = () => {
  const {
    currentUserInfo,
    isUserAdminOrSuperadmin,
    hideSidebar,
    setHideSidebar,
  } = useUserContext();
  const { messageNotificationCount, setMessageNotificationCount } =
    useConversationContext();

  const location = useLocation();
  // const [resetNotificationCount, setResetNotificationCount] = useState(null);

  return (
    <div className="bg-slate-900 md:min-w-fit md:min-h-screen px-5 md:px-0 flex md:flex-col justify-between text-white gap-5 md:gap-0 md:overflow-x-hidden md:overflow-y-auto z-10">
      <div className="py-3 md:py-3 relative flex flex-row md:flex-col flex-wrap justify-center md:justify-evenly gap-3 md:gap-0">
        <button
          type="button"
          onClick={() => setHideSidebar(!hideSidebar)}
          title={hideSidebar ? "Show" : "Hide"}
          className={
            "my-hover-effect w-fit hidden md:block md:absolute md:top-2 md:right-0"
          }
        >
          {hideSidebar ? (
            <BsCaretRightSquareFill size={20} />
          ) : (
            <BsCaretLeftSquareFill size={20} />
          )}
        </button>
        <NavLink
          to="/dashboard/overview"
          title={hideSidebar ? "Overview" : ""}
          className={({ isActive }) =>
            isActive
              ? "my-button-style-2-selected md:border-b-[0.5px] md:border-slate-600"
              : "my-button-style-2 md:border-b-[0.5px] md:border-slate-600"
          }
        >
          <span className="w-full flex gap-2">
            <AiTwotoneShop size="1.4rem" />
            {!hideSidebar && <h3 className="hidden md:block">Overview</h3>}
          </span>
        </NavLink>
        <NavLink
          to="/dashboard/chats"
          title={hideSidebar ? "Chats" : ""}
          className={({ isActive }) =>
            isActive
              ? "my-button-style-2-selected md:border-b-[0.5px] md:border-slate-600"
              : "my-button-style-2 md:border-b-[0.5px] md:border-slate-600"
          }
        >
          <span
            className="w-full flex items-center gap-2"
            onClick={() => {
              setMessageNotificationCount(0);
              // setResetNotificationCount(0);
            }}
          >
            <IoMdChatbubbles size="1.4rem" />
            {!hideSidebar && <h3 className="hidden md:block">Chats</h3>}
            {messageNotificationCount > 0 &&
              location?.pathname !== "/dashboard/chats" && (
                <span className="bg-red-500 w-6 h-6 p-1 grid place-items-center text-white text-xs font-bold rounded-full">
                  {messageNotificationCount}
                </span>
              )}
          </span>
        </NavLink>
        <NavLink
          to="/dashboard/templates"
          title={hideSidebar ? "Templates" : ""}
          className={({ isActive }) =>
            isActive
              ? "my-button-style-2-selected md:border-b-[0.5px] md:border-slate-600"
              : "my-button-style-2 md:border-b-[0.5px] md:border-slate-600"
          }
        >
          <span className="w-full flex gap-2">
            <HiTemplate size="1.4rem" />
            {!hideSidebar && <h3 className="hidden md:block">Templates</h3>}
          </span>
        </NavLink>
        {isUserAdminOrSuperadmin() ? (
          <NavLink
            to="/dashboard/contacts"
            title={hideSidebar ? "Contacts" : ""}
            className={({ isActive }) =>
              isActive
                ? "my-button-style-2-selected md:border-b-[0.5px] md:border-slate-600"
                : "my-button-style-2 md:border-b-[0.5px] md:border-slate-600"
            }
          >
            <span className="w-full flex gap-2">
              <MdPermContactCalendar size="1.4rem" />
              {!hideSidebar && <h3 className="hidden md:block">Contacts</h3>}
            </span>
          </NavLink>
        ) : (
          <></>
        )}

        <NavLink
          to="/dashboard/campaign"
          title={hideSidebar ? "Campaign" : ""}
          className={({ isActive }) =>
            isActive
              ? "my-button-style-2-selected md:border-b-[0.5px] md:border-slate-600"
              : "my-button-style-2 md:border-b-[0.5px] md:border-slate-600"
          }
        >
          <span className="w-full flex gap-2">
            <RiMailSendFill size="1.4rem" />
            {!hideSidebar && <h3 className="hidden md:block">Campaign</h3>}
            {(!currentUserInfo?.isActiveSubscription ||
              (currentUserInfo?.pricingModel === 1 &&
                currentUserInfo?.balanceCoins === 0)) && <FaLock size={10} />}
          </span>
        </NavLink>
        <NavLink
          to="/dashboard/monitor"
          title={hideSidebar ? "Monitor Metrics" : ""}
          className={({ isActive }) =>
            isActive
              ? "my-button-style-2-selected md:border-b-[0.5px] md:border-slate-600"
              : "my-button-style-2 md:border-b-[0.5px] md:border-slate-600"
          }
        >
          <span className="w-full flex gap-2">
            <MdMonitorHeart size="1.4rem" />
            {!hideSidebar && <h3 className="hidden md:block">Monitor</h3>}
            {(!currentUserInfo?.isActiveSubscription ||
              (currentUserInfo?.pricingModel === 1 &&
                currentUserInfo?.balanceCoins === 0)) && <FaLock size={10} />}
          </span>
        </NavLink>

        {currentUserInfo?.catalogId && currentUserInfo?.catalogId !== "" ? (
          <NavLink
            to="/dashboard/catalog"
            title={hideSidebar ? "Catalog" : ""}
            className={({ isActive }) =>
              isActive
                ? "my-button-style-2-selected md:border-b-[0.5px] md:border-slate-600"
                : "my-button-style-2 md:border-b-[0.5px] md:border-slate-600"
            }
          >
            <span className="w-full flex gap-2">
              <GiOpenBook size="1.4rem" />
              {!hideSidebar && <h3 className="hidden md:block">Catalog</h3>}
              {(!currentUserInfo?.isActiveSubscription ||
                (currentUserInfo?.pricingModel === 1 &&
                  currentUserInfo?.balanceCoins === 0)) && <FaLock size={10} />}
            </span>
          </NavLink>
        ) : (
          <></>
        )}

        {isUserAdminOrSuperadmin() ? (
          <NavLink
            to="/dashboard/agents"
            title={hideSidebar ? "Agents" : ""}
            className={({ isActive }) =>
              isActive
                ? "my-button-style-2-selected md:border-b-[0.5px] md:border-slate-600"
                : "my-button-style-2 md:border-b-[0.5px] md:border-slate-600"
            }
          >
            <span className="w-full flex gap-2">
              <PiUsersFill size="1.4rem" />
              {!hideSidebar && <h3 className="hidden md:block">Agents</h3>}
              {(!currentUserInfo?.isActiveSubscription ||
                (currentUserInfo?.pricingModel === 1 &&
                  currentUserInfo?.balanceCoins === 0)) && <FaLock size={10} />}
            </span>
          </NavLink>
        ) : (
          <></>
        )}

        {/* {isUserAdminOrSuperadmin() ? (
          <NavLink
            to="/dashboard/welcome-messages"
            title={hideSidebar ? "Welcome Messages" : ""}
            className={({ isActive }) =>
              isActive
                ? "my-button-style-2-selected md:border-b-[0.5px] md:border-slate-600"
                : "my-button-style-2 md:border-b-[0.5px] md:border-slate-600"
            }
          >
            <span className="w-full flex gap-2">
              <FaPrayingHands size="1.4rem" />
              {!hideSidebar && <h3>Welcome Messages</h3>}
            </span>
          </NavLink>
        ) : (
          <></>
        )} */}

        {isUserAdminOrSuperadmin() ? (
          <NavLink
            to="/dashboard/auto-replies"
            title={hideSidebar ? "Auto Replies" : ""}
            className={({ isActive }) =>
              isActive
                ? "my-button-style-2-selected md:border-b-[0.5px] md:border-slate-600"
                : "my-button-style-2 md:border-b-[0.5px] md:border-slate-600"
            }
          >
            <span className="w-full flex gap-2">
              <FaReplyAll size="1.4rem" />
              {!hideSidebar && (
                <h3 className="hidden md:block">Auto Replies</h3>
              )}
              {(!currentUserInfo?.isActiveSubscription ||
                (currentUserInfo?.pricingModel === 1 &&
                  currentUserInfo?.balanceCoins === 0)) && <FaLock size={10} />}
            </span>
          </NavLink>
        ) : (
          <></>
        )}

        {/* {isUserAdminOrSuperadmin() ? (
          <NavLink
            to="/dashboard/scan-me"
            title={hideSidebar ? "Scan Me" : ""}
            className={({ isActive }) =>
              isActive
                ? "my-button-style-2-selected md:border-b-[0.5px] md:border-slate-600"
                : "my-button-style-2 md:border-b-[0.5px] md:border-slate-600"
            }
          >
            <span className="w-full flex gap-2">
              <FaQrcode size="1.4rem" />
              {!hideSidebar && <h3>Scan Me</h3>}
            </span>
          </NavLink>
        ) : (
          <></>
        )} */}

        {isUserAdminOrSuperadmin() ? (
          <NavLink
            to="/dashboard/follow-ups"
            title={hideSidebar ? "Follow-Ups" : ""}
            className={({ isActive }) =>
              isActive
                ? "my-button-style-2-selected md:border-b-[0.5px] md:border-slate-600"
                : "my-button-style-2 md:border-b-[0.5px] md:border-slate-600"
            }
          >
            <span className="w-full flex gap-2">
              <RiTimer2Fill size="1.4rem" />
              {!hideSidebar && <h3 className="hidden md:block">Follow-Ups</h3>}
              {(!currentUserInfo?.isActiveSubscription ||
                (currentUserInfo?.pricingModel === 1 &&
                  currentUserInfo?.balanceCoins === 0)) && <FaLock size={10} />}
            </span>
          </NavLink>
        ) : (
          <></>
        )}

        {isUserAdminOrSuperadmin() ? (
          <NavLink
            to="/dashboard/assigned-chats"
            title={hideSidebar ? "Assigned Chats" : ""}
            className={({ isActive }) =>
              isActive
                ? "my-button-style-2-selected md:border-b-[0.5px] md:border-slate-600"
                : "my-button-style-2 md:border-b-[0.5px] md:border-slate-600"
            }
          >
            <span className="w-full flex gap-2">
              <MdMarkChatRead size="1.4rem" />
              {!hideSidebar && (
                <h3 className="hidden md:block">Assigned Chats</h3>
              )}
              {(!currentUserInfo?.isActiveSubscription ||
                (currentUserInfo?.pricingModel === 1 &&
                  currentUserInfo?.balanceCoins === 0)) && <FaLock size={10} />}
            </span>
          </NavLink>
        ) : (
          <></>
        )}

        <NavLink
          to="/dashboard/quick-reply"
          title={hideSidebar ? "Quick Reply" : ""}
          className={({ isActive }) =>
            isActive
              ? "my-button-style-2-selected md:border-b-[0.5px] md:border-slate-600"
              : "my-button-style-2 md:border-b-[0.5px] md:border-slate-600"
          }
        >
          <span className="w-full flex gap-2">
            <MdQuickreply size="1.4rem" />
            {!hideSidebar && <h3 className="hidden md:block">Quick Reply</h3>}
            {(!currentUserInfo?.isActiveSubscription ||
              (currentUserInfo?.pricingModel === 1 &&
                currentUserInfo?.balanceCoins === 0)) && <FaLock size={10} />}
          </span>
        </NavLink>

        {/* <NavLink
          to="/dashboard/message-flow"
          title={hideSidebar ? "Design message flow" : ""}
          className={({ isActive }) =>
            isActive
              ? "my-button-style-2-selected md:border-b-[0.5px] md:border-slate-600"
              : "my-button-style-2 md:border-b-[0.5px] md:border-slate-600"
          }
        >
          <span className="w-full flex gap-2">
            <IoLogoFigma size="1.4rem" />
            {!hideSidebar && (
              <h3 className="hidden md:block">Design Message Flow</h3>
            )}
          </span>
        </NavLink> */}

        <NavLink
          to="/dashboard/whatsapp-manager"
          title={hideSidebar ? "Whatsapp Manager" : ""}
          className={({ isActive }) =>
            isActive
              ? "my-button-style-2-selected md:border-b-[0.5px] md:border-slate-600"
              : "my-button-style-2 md:border-b-[0.5px] md:border-slate-600"
          }
        >
          <span className="w-full flex gap-2">
            <IoLogoWhatsapp size="1.4rem" />
            {!hideSidebar && (
              <h3 className="hidden md:block">Whatsapp Manager</h3>
            )}
          </span>
        </NavLink>
      </div>
    </div>
  );
};

export default Sidebar;
