import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { IoClose, IoReload, IoCalendar } from "react-icons/io5";
import { Dialog, DialogBody, DialogHeader } from "@material-tailwind/react";

import { colorsConfig } from "../../config/colorsConfig";
import { useWhatsappContext } from "../../contexts/WhatsappContextProvider";
import { useContactsContext } from "../../contexts/ContactsContextProvider";
import {
  Loader,
  ViewTrackingContactsTable,
  UpdateScheduleDate,
  SeoTags,
  CustomLoader,
} from "..";
import { refreshMonitorData } from "../../data/messageNotifierServices";
import { isoTimestampToLocal } from "../utils/timeStampConversion";

const Monitor = () => {
  const {
    isLoading,
    showScheduledCampaigns,
    scheduledCampaignsData,
    toggleScheduledCampaign,
    selectedJobIdToView,
    setSelectedJobIdToView,
    isNeedResetScheduleDate,
    setOpenReschedule,
    isOpenReschedule,
    selectedJobToView,
    setSelectedJobToView,
  } = useWhatsappContext();
  const { apiCallScheduledCampaignsData } = useContactsContext();

  const [metricType, setMetricType] = useState("broadcast");
  const [reqStatus, setReqStatus] = useState("completed");
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [selectedScheduleId, setSelectedScheduleId] = useState(0);
  const [isScheduleEnabled, setIsScheduleEnabled] = useState();
  const [isOpenRecurringSchedulePopup, setIsOpenRecurringSchedulePopup] =
    useState(false);
  const recurringSchedule = {
    scheduleType: "",
    scheduleDateTime: "",
    scheduleEndDateTime: "",
    scheduleInterval: "",
    intervalType: "",
  };
  const [recurringScheduleData, setRecurringScheduleData] =
    useState(recurringSchedule);
  const scheduleTableColumns = [
    {
      name: "Campaign Name",
      selector: (row) => row?.scheduleName,
      minWidth: "250px",
    },
    {
      name: "Type",
      selector: (row) => row?.scheduleType?.replace("oneTime", "One Time"),
      width: "150px",
      center: true,
    },
    {
      name: "Schedule Date",
      selector: (row) => (
        <div className="flex flex-row items-center justify-between gap-2">
          <span>{isoTimestampToLocal(row?.scheduleDateTime)}</span>
          {row?.scheduleType === "recurring" && (
            <IoCalendar
              onClick={(e) => {
                e.stopPropagation();
                setIsOpenRecurringSchedulePopup(true);
                setRecurringScheduleData({
                  scheduleType: row?.oScheduleType,
                  scheduleDateTime: row?.oScheduleDateTime,
                  scheduleEndDateTime: row?.scheduleEndTime,
                  scheduleInterval: row?.scheduleInterval,
                  intervalType: row?.IntervalType,
                });
              }}
              className="text-green-600"
            ></IoCalendar>
          )}
        </div>
      ),
      width: "250px",
      sortable: true,
    },
    {
      name: "Contact Type",
      selector: (row) => row?.contactType,
      width: "150px",
      center: true,
      sortable: true,
    },
    {
      name: "Contact Info",
      selector: (row) => row?.contactInfo,
      width: "150px",
      sortable: true,
    },
    {
      name: "Template Name",
      selector: (row) => row?.TemplateName,
      width: "300px",
      sortable: true,
    },
    {
      name: "Schedule Status",
      selector: (row) => row?.scheduleStatus,
      width: "150px",
      center: true,
      sortable: true,
    },
    {
      name: "Sent / Total",
      selector: (row) => row?.SCount + "/" + row?.TotalContactCount,
      width: "150px",
      center: true,
    },
    {
      name: "Delivered",
      selector: (row) => row?.deliveredCount,
      width: "150px",
      center: true,
      sortable: true,
    },
    {
      name: "Replies",
      selector: (row) => row?.repliedCount,
      width: "150px",
      center: true,
      sortable: true,
    },
    {
      name: "Action",
      selector: (row) =>
        reqStatus === "active" && (
          <input
            type="checkbox"
            role="switch"
            onChange={() => {
              if (reqStatus === "active") {
                setSelectedScheduleId(row?.scheduleId);
                setIsScheduleEnabled(row?.isEnabled === 1 ? false : true);
                setTimeout(() => {
                  if (isNeedResetScheduleDate === 2) {
                    setOpenReschedule(true);
                  } else {
                    setOpenReschedule(false);
                  }
                }, 1000);
              }
            }}
            checked={row?.isEnabled === 1 ? true : false}
            className="my-toggle-switch"
          />
        ),
      center: true,
    },
  ];

  const broadcastTableColumns = [
    {
      name: "Date",
      selector: (row) => isoTimestampToLocal(row?.scheduleCreationDate),
      width: "250px",
      sortable: true,
    },
    {
      name: "Contact Type",
      selector: (row) => row?.contactType,
      width: "150px",
      center: true,
    },
    {
      name: "Contact Info",
      selector: (row) => row?.contactInfo,
      width: "auto",
      sortable: true,
    },

    {
      name: "Template Name",
      selector: (row) => row?.TemplateName,
      width: "300px",
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row?.scheduleStatus,
      width: "150px",
      center: true,
      sortable: true,
    },
    {
      name: "Sent / Total",
      selector: (row) => row?.SCount + "/" + row?.TotalContactCount,
      width: "150px",
      center: true,
    },
    {
      name: "Delivered",
      selector: (row) => row?.deliveredCount,
      width: "150px",
      center: true,
      sortable: true,
    },
    {
      name: "Replies",
      selector: (row) => row?.repliedCount,
      width: "150px",
      center: true,
      sortable: true,
    },
    {
      name: "Campaign Type",
      selector: (row) => row.scheduleType.replace("oneTime", "One Time"),
      width: "150px",
    },
  ];

  const handleJobToViewOnClick = (job) => {
    setSelectedJobIdToView(job?.scheduleId);
    setSelectedJobToView(job);

    if (job?.scheduleId !== 0) {
      apiCallScheduledCampaignsData(job?.scheduleId);
    }
  };

  useEffect(() => {
    showScheduledCampaigns(reqStatus);
    refreshMonitorData.subscribe((data) => {
      setRefreshCounter(refreshCounter + 1);
      setSelectedJobIdToView(0);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqStatus, refreshCounter]);

  useEffect(() => {
    if (isScheduleEnabled !== undefined) {
      toggleScheduledCampaign(selectedScheduleId, isScheduleEnabled);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isScheduleEnabled]);

  return (
    <main className="w-full space-y-5">
      <SeoTags title="Monitor ‣ WaBM" />

      {isLoading && <CustomLoader />}
      <div className="flex-1 my-1 space-y-3">
        <h1 className="text-2xl">Monitor Campaign Performance Metrics</h1>
        <h3>
          View and Modify Campaign Performance, be it Broadcasting or Scheduled
          Campaigns.
        </h3>
      </div>

      <div className="inline-flex gap-2">
        <button
          type="button"
          onClick={() => {
            setMetricType("broadcast");
            setReqStatus("completed");
          }}
          className={
            metricType === "broadcast"
              ? "my-button-style-1-selected"
              : "my-button-style-5"
          }
        >
          Broadcast
        </button>
        <button
          type="button"
          onClick={() => setMetricType("scheduled")}
          className={
            metricType === "scheduled"
              ? "my-button-style-1-selected"
              : "my-button-style-5"
          }
        >
          Scheduled
        </button>
        <button
          type="button"
          onClick={() => {
            setRefreshCounter(refreshCounter + 1);
          }}
          className="my-button-style-5"
        >
          <IoReload size={28} />
        </button>
      </div>

      {metricType === "scheduled" && (
        <div className="my-card-style-1 space-y-3">
          <span className="space-y-2">
            <h2 className="text-xl font-semibold">Scheduled Campaigns</h2>
            <h3>View & Edit Scheduled Messages and Campaigns.</h3>
          </span>
          <span className="flex items-center gap-3">
            <button
              onClick={() => setReqStatus("active")}
              className={
                reqStatus === "active"
                  ? "my-button-style-1-selected"
                  : "my-button-style-5"
              }
            >
              Active
            </button>
            <button
              onClick={() => setReqStatus("expired")}
              className={
                reqStatus === "expired"
                  ? "my-button-style-1-selected"
                  : "my-button-style-5"
              }
            >
              Expired
            </button>
            <button
              onClick={() => setReqStatus("completed")}
              className={
                reqStatus === "completed"
                  ? "my-button-style-1-selected"
                  : "my-button-style-5"
              }
            >
              Completed
            </button>
          </span>
          <div className="w-full max-h-[70vh] rounded-md overflow-auto">
            {isLoading ? (
              <Loader />
            ) : scheduledCampaignsData.length > 0 ? (
              <div className="my-5 space-y-2 rounded-md">
                <DataTable
                  columns={scheduleTableColumns}
                  onRowClicked={(row) => handleJobToViewOnClick(row)}
                  data={scheduledCampaignsData.filter(
                    (x) => x.STypes === "Schedule"
                  )}
                  customStyles={scheduleTableStyles}
                  highlightOnHover
                  striped
                  fixedHeader
                  fixedHeaderScrollHeight="65vh"
                />
              </div>
            ) : (
              <div className="space-y-3">
                <p>
                  No <strong>{reqStatus}</strong> schedule found.
                </p>
                <p>Schedule your campaign now from Campaign tab.</p>
              </div>
            )}
          </div>
        </div>
      )}

      {metricType === "broadcast" && (
        <div className="my-card-style-1 space-y-3">
          <span className="space-y-2">
            <h2 className="text-xl font-semibold">Broadcast Campaigns</h2>
            <h3>View & Edit Broadcast Messages and Campaigns.</h3>
            <div className="w-full max-h-[70vh] rounded-md overflow-auto">
              {isLoading ? (
                <Loader />
              ) : scheduledCampaignsData.length > 0 ? (
                <div className="my-5 space-y-2 rounded-md">
                  <DataTable
                    columns={broadcastTableColumns}
                    onRowClicked={(row) => handleJobToViewOnClick(row)}
                    data={scheduledCampaignsData.filter(
                      (x) => x.STypes === "BroadCast"
                    )}
                    customStyles={scheduleTableStyles}
                    highlightOnHover
                    striped
                    fixedHeader
                    fixedHeaderScrollHeight="65vh"
                  />
                </div>
              ) : (
                <div className="space-y-3">
                  <p>
                    No <strong>{reqStatus}</strong> Broadcast found.
                  </p>
                  <p>Schedule your campaign now from Campaign tab.</p>
                </div>
              )}
            </div>
          </span>
        </div>
      )}

      <div className="w-full">
        {selectedJobIdToView !== 0 && (
          <div className="my-full-screen-window-style-1">
            <ViewTrackingContactsTable
              scheduleStatus={reqStatus}
              jobId={selectedJobIdToView}
              selectedJob={selectedJobToView}
            />
            <button
              onClick={() => {
                setSelectedJobIdToView(0);
              }}
              className="my-button-style-cancel absolute top-5 right-5"
            >
              <IoClose size={18} />
            </button>
          </div>
        )}
      </div>
      <div className="w-full">
        {isOpenReschedule === true && (
          <div className="my-full-screen-window-style-1 my-full-screen-window-style-2">
            <UpdateScheduleDate jobId={selectedScheduleId} />
          </div>
        )}
      </div>

      {isOpenRecurringSchedulePopup && (
        <Dialog
          className="lg:min-w-[25%] lg:w-[25%]"
          open={isOpenRecurringSchedulePopup}
          handler={() =>
            setIsOpenRecurringSchedulePopup(!isOpenRecurringSchedulePopup)
          }
        >
          <DialogHeader
            color="green"
            iconOnly
            onClose={() => setIsOpenRecurringSchedulePopup(false)}
          >
            <div className="text-green-700 text-2xl font-bold">
              Recurring Schedule Details
            </div>
          </DialogHeader>
          <DialogBody>
            <div className="flex flex-col gap-3 border p-3 rounded-md border-gray-300">
              <div className="flex flex-row gap-3">
                <span className="font-semibold">Schedule Type</span>
                <span>{recurringScheduleData.scheduleType}</span>
              </div>
              <div className="flex flex-row gap-3">
                <span className="font-semibold">Schedule Start Date</span>
                <span>
                  {isoTimestampToLocal(recurringScheduleData.scheduleDateTime)}
                </span>
              </div>
              <div className="flex flex-row gap-3">
                <span className="font-semibold">Schedule End Date</span>
                <span>
                  {isoTimestampToLocal(
                    recurringScheduleData.scheduleEndDateTime
                  )}
                </span>
              </div>
              <div className="flex flex-row gap-3">
                <span className="font-semibold">Schedule Interval</span>
                <span>{recurringScheduleData.scheduleInterval}</span>
              </div>
              <div className="flex flex-row gap-3">
                <span className="font-semibold">Interval Type</span>
                <span>{recurringScheduleData.intervalType}</span>
              </div>
            </div>
          </DialogBody>
        </Dialog>
      )}
    </main>
  );
};

export default Monitor;

const scheduleTableStyles = {
  rows: {
    style: {},
  },
  headCells: {
    style: {
      backgroundColor: colorsConfig.mediumGrey,
      color: "#FFFFFF",
      fontSize: "0.9rem",
    },
  },
  cells: {
    style: {
      "&:hover": {
        cursor: "pointer",
      },
    },
  },
};
