import React, { createContext, useContext } from "react";
import { toast } from "react-toastify";

import { appSettings } from "../config/appConfig";
import { useUserContext } from "./UserContextProvider";

const ScanMeContext = createContext();

export const ScanMeContextProvider = ({ children }) => {
  const { setIsLoading } = useUserContext();
  const baseUrl = appSettings.serverBaseUrl;

  const createScanMeLink = async (scanMeLink) => {
    setIsLoading(true);
    try {
      const response = await fetch(`${baseUrl}/scan-me/create-scan-me-link`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(scanMeLink),
      });

      const result = await response.json();

      if (result?.Error) {
        toast.error(result.Error);
      } else if (result?.message) {
        toast.success(result.message);
      }
    } catch (err) {
      toast.error("Failed to create scan me link");
    } finally {
      setIsLoading(false);
    }
  };

  const getScanMeLink = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${baseUrl}/scan-me/get-scan-me-link`, {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const scanMeLinks = await response.json();
      return scanMeLinks;
    } catch (err) {
      toast.error("Failed to get scan me links");
      return [];
    } finally {
      setIsLoading(false);
    }
  };

  const removeScanMeLink = async (messageId) => {
    setIsLoading(true);
    try {
      const response = await fetch(`${baseUrl}/scan-me/delete-scan-me-link`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ messageId: messageId }),
      });
      const result = await response.json();
      if (result?.Error) {
        toast.error(result.Error);
      } else if (result?.message) {
        toast.success(result.message);
      }
    } catch (err) {
      console.log(err);
      toast.error("Failed to remove scan me link");
    } finally {
      setIsLoading(false);
    }
  };

  const updateScanMeProfileName = async (profileName) => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${baseUrl}/scan-me/update-scan-me-profile-name`,
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ profileName: profileName }),
        }
      );

      const result = await response.json();

      if (result?.Error) {
        toast.error(result.Error);
      } else if (result?.message) {
        toast.success(result.message);
      }
    } catch (err) {
      toast.error("Failed to update scan me profile name");
    } finally {
      setIsLoading(false);
    }
  };

  const getScanMeProfileName = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${baseUrl}/scan-me/get-scan-me-profile-name`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const profileName = await response.json();
      return profileName;
    } catch (err) {
      console.log(err);
      toast.error("Failed to get scan me profile name");
      return [];
    } finally {
      setIsLoading(false);
    }
  };

  const getScanMeLinkByProfileName = async (profileName) => {
    try {
      const response = await fetch(
        `${baseUrl}/scan-me/get-scan-me-link-by-profile-name?profileName=${profileName}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const scanMeLinks = await response.json();
      return scanMeLinks;
    } catch (err) {
      console.log("errr", err);
      toast.error("Failed to get scan me link");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ScanMeContext.Provider
      value={{
        createScanMeLink,
        getScanMeLink,
        removeScanMeLink,
        updateScanMeProfileName,
        getScanMeProfileName,
        getScanMeLinkByProfileName,
      }}
    >
      {children}
    </ScanMeContext.Provider>
  );
};

export const useScanMeContext = () => useContext(ScanMeContext);
