import React, { useState, useEffect } from "react";
import {
  Button,
  Input,
  Dialog,
  DialogHeader,
  DialogBody,
} from "@material-tailwind/react";
import * as Icons from "react-icons/fa";
import { IoPersonCircleSharp } from "react-icons/io5";
import { MdDeleteOutline } from "react-icons/md";
import { RiCloseCircleFill } from "react-icons/ri";
import { toast } from "react-toastify";

import { aboutApp } from "../../config/appConfig";
import { useScanMeContext } from "../../contexts/ScanMeContextProvider";
import { inputUsernameSanitation } from "../utils/inputSanitation";

const ManageScanMe = () => {
  const {
    createScanMeLink,
    getScanMeLink,
    removeScanMeLink,
    updateScanMeProfileName,
    getScanMeProfileName,
  } = useScanMeContext();

  const domainUrl = aboutApp.website;

  const iconsList = [
    { id: "FaBeer", icon: "FaBeer" },
    { id: "FaCoffee", icon: "FaCoffee" },
    { id: "FaApple", icon: "FaApple" },
    // Add more icons as needed
  ];

  const [openDialog, setOpenDialog] = useState(false);
  const [openThumbnailDialog, setOpenThumbnailDialog] = useState(false);
  const [pageUrl, setPageUrl] = useState(false);
  const [pageTitle, setPageTitle] = useState(false);
  const [scanMeLinks, setScanMeLinks] = useState([]);
  const [draggingItem, setDraggingItem] = useState(null);
  const [refreshPageCounter, setRefreshPageCounter] = useState(0);
  const [selectedIcon, setSelectedIcon] = useState("FaJsfiddle");
  const [scanMeProfileName, setScanMeProfileName] = useState("");
  const liveUrl = `${domainUrl}/mylinks/${scanMeProfileName}`;

  const submitOnCreateLink = async (e) => {
    e.preventDefault();

    await createScanMeLink({
      page_link: pageUrl,
      page_title: pageTitle,
      thumbnail_link: selectedIcon === "FaJsfiddle" ? "" : selectedIcon,
    });
    setOpenDialog(false);
    setRefreshPageCounter((prvCounter) => prvCounter + 1);
  };

  const loadScanMeLinks = async () => {
    const response = await getScanMeLink();
    setScanMeLinks(response);
    //console.log("response", response);
  };

  const loadScanMeProfileName = async () => {
    const response = await getScanMeProfileName();
    setScanMeProfileName(response?.[0]?.profileName);

    //console.log("response", response);
  };

  const handleDragStart = (e, index) => {
    e.dataTransfer.setData("text/plain", index);
    // Directly use the element as drag image
    const node = e.target;
    e.dataTransfer.setDragImage(node, 0, 0);
    setDraggingItem(index);
  };

  const handleDrop = (e, index) => {
    e.preventDefault();
    // const draggedIndex = e.dataTransfer.getData("text/plain");
    //setToReorderMessages(true);

    const updatedItems = [...scanMeLinks];
    const draggedItem = updatedItems[draggingItem];

    // Remove the item from its original position
    updatedItems.splice(draggingItem, 1);

    // Insert the item at the new index
    updatedItems.splice(index, 0, draggedItem);

    setDraggingItem(null);
    setScanMeLinks(updatedItems);
  };

  const deleteScanMeLink = async (e, messageId) => {
    e.preventDefault();
    await removeScanMeLink(messageId);
    setRefreshPageCounter((prvCounter) => prvCounter + 1);
  };

  const IconDropdown = () => {
    const handleSelect = (e, icon) => {
      e.preventDefault();
      setSelectedIcon(icon);
      setOpenThumbnailDialog(false);
    };

    return (
      <div className="grid grid-cols-12">
        {iconsList.map((item) => {
          const IconComponent = Icons[item.icon];
          return (
            <div key={item.id} onClick={(e) => handleSelect(e, item.icon)}>
              <IconComponent className="w-9 h-9 hover:cursor-pointer hover:text-green-600" />
              {/* {item.icon} */}
            </div>
          );
        })}
      </div>
    );
  };

  const DisplayIcon = ({ selectedIcon }) => {
    const IconComponent = Icons[selectedIcon];
    return (
      <div>
        <IconComponent
          size={30}
          className="text-blue-700 hover:cursor-pointer"
        />
      </div>
    );
  };

  const copyToClipboard = (copyText) => {
    navigator.clipboard
      .writeText(copyText)
      .then(() => {
        toast.info("Copied the Scan Me Page Link");
      })
      .catch((err) => {
        toast.error("Failed to copy text: ", err);
      });
  };

  useEffect(() => {
    loadScanMeLinks();
    loadScanMeProfileName();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshPageCounter]);

  return (
    <main className="w-full mt-5 mb-10 lg:my-1 flex justify-betweenflex justify-between gap-5">
      <div className="w-full space-y-5">
        <div className="w-full space-y-2">
          <h1 className="text-2xl">Scan Me</h1>
          <h3>Create, Delete and Manage Scan Me Page.</h3>
        </div>
        <div className="space-y-1">
          <p className="text-sm">Live Link</p>
          {!scanMeProfileName || scanMeProfileName === "" ? (
            <p className="bg-red-500 w-fit px-2 text-white text-sm">
              Link Not Generated Yet
            </p>
          ) : (
            <span
              onClick={() => copyToClipboard(liveUrl)}
              className="text-blue-700"
            >
              {liveUrl}
            </span>
          )}
        </div>
        <div className="w-full md:w-full xl:w-1/2 flex gap-5">
          <Input
            variant="standard"
            color="green"
            label="Scan Me Profile Name"
            value={scanMeProfileName}
            onChange={(e) =>
              setScanMeProfileName(inputUsernameSanitation(e.target.value))
            }
          />
          <Button
            type="button"
            variant="gradient"
            color="blue"
            onClick={() => {
              updateScanMeProfileName(scanMeProfileName);
            }}
            className="w-full md:w-36 xl:w-auto"
          >
            Update
          </Button>
        </div>

        <div className="bg-slate-300 w-full h-[2px] rounded" />

        <Button
          type="button"
          variant="gradient"
          color="blue"
          className="w-28"
          onClick={() => {
            setSelectedIcon("FaJsfiddle");
            setOpenDialog(true);
          }}
        >
          Add Link
        </Button>
      </div>
      <div className="flex flex-row justify-start">
        {Array.isArray(scanMeLinks) &&
          scanMeLinks?.map((item, index) => (
            <div
              key={index}
              draggable
              onDragStart={(e) => handleDragStart(e, index)}
              onDragOver={(e) => e.preventDefault()}
              onDrop={(e) => handleDrop(e, index)}
              className="w-[95%] flex-col justify-start gap-1 mb-2 p-5 rounded-md bg-gray-100"
            >
              <div className="flex flex-row justify-between gap-2">
                <div className="flex flex-col gap-1">
                  <div className="font-bold text-lg">{item?.pageTitle}</div>
                  <div className="">{item?.pageLink}</div>
                  <DisplayIcon selectedIcon={item?.thumbnailLink}></DisplayIcon>
                </div>

                <Button
                  variant="gradient"
                  color="white"
                  onClick={(e) => deleteScanMeLink(e, item?._id)}
                >
                  <MdDeleteOutline className=" w-10 h-10 text-red-700"></MdDeleteOutline>
                </Button>
              </div>
            </div>
          ))}

        <div className="flex flex-col justify-start bg-slate-200 shadow-lg p-5 rounded-[40px] h-[75vh] w-[25vw]">
          <div className="h-32 w-32 rounded-full self-center mb-3">
            <IoPersonCircleSharp className="bg-white w-32 h-auto  text-wabmBlue drop-shadow-md rounded-full" />
          </div>
          <div className="self-center text-lg mb-8 text-blue-800 font-bold">
            {scanMeProfileName}
          </div>
          {Array.isArray(scanMeLinks) &&
            scanMeLinks?.map((item, index) => (
              <div
                key={index}
                className="flex-col justify-start gap-2 mb-2 pr-8 pl-4 rounded-lg bg-white"
              >
                <div className="flex flex-row justify-between gap-4">
                  <a
                    href={item?.pageLink}
                    className="flex flex-row gap-1 h-20 justify-between items-center hover:cursor-pointer"
                  >
                    <DisplayIcon
                      selectedIcon={item?.thumbnailLink}
                    ></DisplayIcon>
                    <div className="text-lg text-blue-800">
                      {item?.pageTitle}
                    </div>
                  </a>
                </div>
              </div>
            ))}
        </div>
      </div>

      <Dialog open={openDialog} size="sm" className="w-[20%]">
        <DialogHeader>
          <div className="flex flex-row justify-between w-full">
            <div className="text-red-700 font-bold text-lg self-start">
              Add a Link
            </div>
            <RiCloseCircleFill
              onClick={() => setOpenDialog(false)}
              size={30}
              className="text-red-500 hover:text-red-600 transition hover:cursor-pointer duration-300 ease-in-out self-end"
            />
          </div>
        </DialogHeader>
        <DialogBody>
          <form
            onSubmit={(e) => submitOnCreateLink(e)}
            className="flex flex-col gap-2 justify-start"
          >
            <Input
              type="text"
              variant="standard"
              required
              label="Input page title"
              value={pageTitle}
              onChange={(e) => setPageTitle(e.target.value)}
              className="h-7 text-lg"
            />
            <Input
              type="text"
              variant="standard"
              required
              label="Input Link"
              value={pageUrl}
              onChange={(e) => setPageUrl(e.target.value.replace(/\s+/g, ""))}
              className="h-7 text-lg"
            />
            <div className="text-green-700">Select Thumbnail</div>
            <div
              onClick={(e) => {
                setOpenThumbnailDialog(true);
              }}
              className="h-12 w-12"
            >
              <DisplayIcon selectedIcon={selectedIcon}></DisplayIcon>
            </div>
            <div className="flex flex-row-reverse justify-between">
              <Button
                type="submit"
                className="self-end"
                variant="filled"
                color="green"
              >
                Confirm
              </Button>
            </div>
          </form>
        </DialogBody>
      </Dialog>
      <Dialog
        open={openThumbnailDialog}
        size="sm"
        className="w-[20%]"
        handler={() => setOpenThumbnailDialog(!openThumbnailDialog)}
      >
        <DialogHeader>
          <div className="text-red-700 font-bold text-lg">Select Thumbnail</div>
        </DialogHeader>
        <DialogBody>
          <div className="flex flex-col gap-2 justify-start">
            <IconDropdown />
          </div>
        </DialogBody>
      </Dialog>
    </main>
  );
};

export default ManageScanMe;
